import React, { useState, useEffect } from 'react';
import { Card, Typography } from 'antd';
import scoreResult from '../helpers/scoreResult';
import scoreColor from '../helpers/scoreColor';


const { Text, Title } = Typography;


const CardViewAnalysis = ({ result, color }) => {

    return (
        <Card
            size='small'
            title={<>
                {`${result.evaluation}`}
            </>
            }
            extra={scoreResult(result)}
            style={{ 
                width: '100%', 
                marginBottom: '10px',
                borderLeft: `5px solid ${color ? color : scoreColor(result)}` // Add red strip to the left side 
            }}
        >
            <p>{result.feedback}</p>
        </Card>
    );
}


export default CardViewAnalysis;