import { Card, Row, Col, Pagination } from 'antd';
import { CheckSquareFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';

const ToDoGrid = ({ elements }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 4;

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const paginatedElements = elements.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    return (
        <div>
            <Row gutter={[16, 16]}>
                {paginatedElements.map((item, index) => (
                    <Col xs={24} sm={12} key={index}>
                        <Card
                            title={item.text}
                            extra={item.icon ? item.icon : <CheckSquareFilled />}
                            actions={[<Link to={item.link}><p className={item.linkClass} style={{ fontSize: '1em' }}>View</p></Link>]}
                        >
                            <Card.Meta
                                description={item.description || "No description available."}
                            />
                            {/* {item.description || "No description available."} */}
                        </Card>
                    </Col>
                ))}
                
            </Row>
            <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={elements.length}
                onChange={handlePageChange}
                size='small'
                style={{ textAlign: 'center', marginTop: '5px', marginBottom: '5px' }}
            />
        </div>
    );
};

export default ToDoGrid;