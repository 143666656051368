import React, { useState, useContext, useEffect } from 'react';
import { List, Card, Badge, Button, Divider, Select, Input } from 'antd';
import { UserContext } from '../../../contexts/userContext'

const { Option } = Select;


const EmailList = ({ selectEmailThread }) => {

    const { user } = useContext(UserContext);
    const { allMyEmails } = useContext(UserContext);

    const [groupedEmails, setGroupedEmails] = useState([]);
    const [sortedGroupedEmails, setSortedGroupedEmails] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [showUnreadOnly, setShowUnreadOnly] = useState(false);

    const sampleEmail = {
        direction: 'received',
        fromEmail: 'Hiremize',
        toEmail: 'sample@sample.com',
        subject: 'Welcome!',
        body: 'Welcome to Hiremize! Currently, you have not received any replies. Please check back later. View "Contacts" to see your connections and messages sent.',
        read: true,
        createdAt: Date.now()
      };


    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    }
    
    useEffect(() => {
        let filteredEmails = groupedEmails.filter(email => 
            email.subject.toLowerCase().includes(searchQuery.toLowerCase()) || 
            email.fromEmail.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setSortedGroupedEmails(filteredEmails);
    }, [searchQuery, groupedEmails]);

    useEffect(() => {
        // ---- Group emails by thread -----
        // const emailsGroupedByThread = allMyEmails.reduce((acc, email) => {
        //     if (email.toEmail === user.customUserEmail) {
        //         return acc; // Skip this email
        //     }
        //     const threadId = [email.toEmail, email.fromEmail].sort().join('-');
        //     if (!acc[threadId]) {
        //         acc[threadId] = [];
        //     }
        //     acc[threadId].push(email);
        //     return acc; 
        // }, {});

        const emailsGroupedByFromEmail = allMyEmails.reduce((acc, email) => {

            if (user && email.fromEmail === user.customUserEmail) {
                return acc; // Skip the users email
            }

            const fromEmail = email.fromEmail;
            if (!acc[fromEmail]) {
                acc[fromEmail] = [];
            }
            acc[fromEmail].push(email);
            return acc;
        }, {})

        // Convert the object to an array of email groups
        const latestEmails = Object.values(emailsGroupedByFromEmail).map(emailGroup => {
            // Sort the email group by date in descending order and return the latest email
            return emailGroup.sort((a, b) => new Date(parseInt(b.createdAt)) - new Date(parseInt(a.createdAt)))[0];
        });

        setGroupedEmails(latestEmails);
        setSortedGroupedEmails(latestEmails);
    }, [allMyEmails, user]);

    
    const byDate = (a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
    }

    const bySender = (a, b) => {
        return a.fromEmail.localeCompare(b.fromEmail);
    }

    const bySubject = (a, b) => {
        return a.subject.localeCompare(b.subject);
    }

    const sortEmails = (sortType) => {
        let sortedEmails = [...groupedEmails];
        switch (sortType) {
            case 'date':
                sortedEmails.sort(byDate);
                break;
            case 'sender':
                sortedEmails.sort(bySender);
                break;
            case 'subject':
                sortedEmails.sort(bySubject);
                break;
            default:
                break;
        }
        setSortedGroupedEmails(sortedEmails);
    }

    const showUnread = () => {
        if (showUnreadOnly) {
            // If the "unread" filter is currently active, deactivate it and show all emails
            setSortedGroupedEmails(groupedEmails);
            setShowUnreadOnly(false);
        } else {
            // If the "unread" filter is currently inactive, activate it and show only unread emails
            let unreadEmails = groupedEmails.filter(email => !email.read);
            setSortedGroupedEmails(unreadEmails);
            setShowUnreadOnly(true);
        }
    }


    const fromItem = (email) => {
        return (
            <div
                onClick={() => selectEmailThread(email)}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: '10px',
                    borderBottom: '1px solid #d9d9d9',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s',
                    ':hover': {
                        backgroundColor: '#f5f5f5'
                    }
                }}
            >
                <Badge
                    count={!email.read ? 'New!' : ''}
                    style={{
                        backgroundColor: 'green',
                    }}
                    offset={[-25, 50]}
                >
                    <div
                        style={{
                            width: '50px',
                            height: '50px',
                            borderRadius: '50%',
                            backgroundColor: '#2ca4cc',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: 'white',
                            marginRight: '10px',
                            fontSize: '20px',
                        }}
                    >
                        {email.toEmail[0].toUpperCase()}
                    </div>
                </Badge>
                <div>
                    <p style={{ margin: 0, fontWeight: 'bold' }}>{email.fromEmail}</p>
                    <p style={{ margin: 0 }}>{email.subject}</p>
                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                        {/* {!email.read && <span style={{ marginRight: '5px', color: '#2ca4cc', borderColor: '#fcdc5c', fontWeight: 'bold' }}>New!</span>} */}
                        <p style={{ margin: 0, fontSize: '11px' }}>{new Date(parseInt(email.createdAt)).toLocaleString()}</p>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div>
            <div style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: '#fff' }}>
                <div style={{ margin: '10px' }}>
                    <h2 className="title" style={{ fontFamily: "-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'", fontSize: '34px', fontWeight: 'bold' }}>Inbox</h2>
                    <Input placeholder="Search..." onChange={handleSearch}/>
                    <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', marginTop: '10px' }}>
                        <Select defaultValue="" onChange={sortEmails}>
                            <Option value="">Sort by...</Option>
                            <Option value="date">Date</Option>
                            <Option value="sender">Sender</Option>
                            <Option value="subject">Subject</Option>
                        </Select>
                        <Button onClick={showUnread}>Show Unread</Button>
                    </div>
                    {/* Add more filter buttons as needed */}
                    <Divider />
                </div>
            </div>
            <div style={{ overflow: 'auto', height: 'calc(100vh - 100px)' }}>
                <List
                    dataSource={sortedGroupedEmails.length > 0 ? sortedGroupedEmails : [sampleEmail]}
                    renderItem={email => (
                        fromItem(email)
                    )}
                />
            </div>
        </div>
    )

}

export default EmailList