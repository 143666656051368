import React, { useState, useEffect, useContext } from 'react';
import { List, Button, Divider, Card, notification } from 'antd';
import EmailItem from './EmailItem';
import EmailCompose from './EmailComposer';
import EmailButtons from './EmailButtons';
import 'react-chat-elements/dist/main.css';
import { useMutation } from '@apollo/client';
import { MARK_EMAIL_READ } from '../../utils/mutations';
import { fetchSendEmail } from '../../utils/EmailAPIs/sendEmail.js';
import { UserContext } from '../../contexts/userContext.js';
import './MailBox.css';


const MailBox = ({ contactName, myEmails, userContactId, toEmail }) => {

    const { user } = useContext(UserContext);
    const [emails, setEmails] = useState(myEmails);
    const [newSubject, setNewSubject] = useState('');
    const [newBody, setNewBody] = useState('');
    const [selectedEmail, setSelectedEmail] = useState(null);
    const [markEmailRead] = useMutation(MARK_EMAIL_READ);
    const [mostRecentEmail, setMostRecentEmail] = useState(null);
    const [anyEmailReceived, setAnyEmailReceived] = useState(false);


    useEffect(() => {
        setEmails(myEmails);
        if (myEmails.length > 0) {
            if (myEmails.length > 0) {
                setNewSubject(myEmails[myEmails.length - 1].subject);
                setMostRecentEmail(myEmails[myEmails.length - 1]);
            }
            //Check if any email in myEmails has a direction of received
            const receivedEmail = myEmails.find(email => email.direction === 'received');
            if (receivedEmail) {
                setAnyEmailReceived(true);
            }
        }


    }, [myEmails]);


    useEffect(() => {
        emails.forEach(email => {
            if (!email.read) {
                markEmailRead({
                    variables: { id: email._id, read: true }
                });
            }
        }
        );
    }, []);

    //ToDo: get rid of placeholders
    const handleChatBoxUpdate = () => {
        if (newSubject.trim() && newBody.trim()) {
            setEmails([
                ...emails,
                {
                    direction: 'sent',
                    type: 'text',
                    text: newBody,
                    date: new Date(),
                    subject: newSubject,
                    body: newBody,
                    fromEmail: 'recipient@example.com',
                    toEmail: 'sender@example.com',
                    userId: 'user123',
                    headers: { 'X-Contact-ID': 'contact123' },
                },
            ]);
            setNewSubject('');
            setNewBody('');
        }
    };

    const forwardEmailToUser = async () => {

        try {
            await fetchSendEmail(user.email, user.customUserEmail, mostRecentEmail.subject, `From: ${toEmail}\n\n ${mostRecentEmail.body}`, "", user._id, true);
            // Show success notification
            notification.success({
                message: 'Email Forwarded',
                description: 'The email has been successfully forwarded to your personal email.',
                placement: 'topRight',
            });
        } catch (error) {
            console.error("Error forwarding email:", error);
        }

    }

    return (
        <div>
            <div>
                <div className="header-container">
                    <h2 className="contact-name">{contactName}</h2>
                    {anyEmailReceived && (
                        <Button className="button-color forward-button" onClick={forwardEmailToUser} type="primary">
                            Forward Email
                        </Button>
                    )}
                </div>
            </div>

            <Divider />
            <div style={{ margin: '0 auto' }}>
                <div style={{ height: '50vh', overflow: 'auto' }}>
                    <List
                        className="email-list"
                        dataSource={emails}
                        renderItem={email => (
                            <EmailItem email={email} onClick={() => setSelectedEmail(email)} />
                        )}
                    />
                </div>
                <Divider />
                <EmailCompose
                    newSubject={newSubject}
                    setNewSubject={setNewSubject}
                    newBody={newBody}
                    setNewBody={setNewBody}
                    replyToMessage={myEmails.length > 0 && emails[myEmails.length - 1] ? emails[myEmails.length - 1].body : ''}
                />
                <EmailButtons
                    handleChatBoxUpdate={handleChatBoxUpdate}
                    toEmail={toEmail}
                    subject={newSubject}
                    body={newBody}
                    userContactId={userContactId}
                />
            </div>
        </div>
    );
};

export default MailBox;



//React Chat Elements - https://detaysoft.github.io/docs-react-chat-elements/