import React, { useEffect, useState } from "react";
import '../Card.css'
import { useMutation } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { ADD_APPLICATION } from '../../../utils/mutations.js'
import { QUERY_JOBS_SAVED } from '../../../utils/queries.js'
import { Card, Button } from 'antd'
import MessageModal from "../../Modals/MessageModal"

import { useLocation } from 'react-router-dom'; // Import useLocation from react-router-dom


const JobPostingItem = ({ job, forMatcher }) => {
  const location = useLocation(); // Get the current route location

  const [addApplication, { error }] = useMutation(ADD_APPLICATION);
  const { loading, savedError, data, refetch } = useQuery(QUERY_JOBS_SAVED, { variables: { id: job._id } })
  const [isSaved, setIsSaved] = useState(false);
  const [isApplied, setIsApplied] = useState(false);
  const [appStatus, setAppStatus] = useState("");

  const [MessageModalStatus, setMessageModalStatus] = useState(false);
  const [currentMessageType, setCurrentMessageType] = useState('');

  const openMessageModal = (type) => {
    setCurrentMessageType(type);
    setMessageModalStatus(true);
    console.log(MessageModalStatus);
  };

  const closeMessageModal = () => {
    setMessageModalStatus(false);
  };

  useEffect(() => {
    if (location.pathname === '/') {
      refetch(); // Refetch the data when the route is "/applications"
      console.log("refetching");
    }
  }, [location.pathname, refetch]);


  useEffect(() => {
    if (data) {
      setIsSaved(data.getApplicationByJobID.success);
      setAppStatus(data.getApplicationByJobID.status);
      if (data.getApplicationByJobID.status === "Applied") {
        setIsApplied(true);
      }
    }
  }, [data])

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const handleSave = async (id) => {
    try {
      await addApplication({
        variables: { job: id, status: "Saved" }
      });
      setIsSaved(true);
      setAppStatus("Saved");
    } catch (error) {
      // Handle any errors that occur during the mutation.
      console.error("Error adding application:", error);
    }
  }

  const actionButton =
    !isSaved ? (
      <Button className="button-color" onClick={() => handleSave(job._id)} type="primary">
        Save
      </Button>
    )
      : isApplied ? (
        <div>
          <Button onClick={() => openMessageModal("Follow-Up")} type="default">Follow Up</Button>
        </div>
      )
        : (
          <span>Status: {appStatus}</span>
        );



  return (
    <Card className="posting-card">
      <div className="item-label">Job Posting</div>
      <div className="logo-container">
        <img
          src={job.logo}
          alt={`${job.company} Logo`}
          className="logo-image"
          loading="lazy"
        />
      </div>
      <div className="text-container">
        <span className="company-name">{job.company}</span>
        <h4>
          <a href={`${job.link}`} target="_blank">{job.position}</a>
        </h4>
        <div className="location">
          <span className="icon flaticon-map-locator"></span>
          {job.location}
        </div>
      </div>
      {!forMatcher &&
        <>
          <hr className="divider" /> {/* Add a horizontal line */}
          <div className="action-container">{actionButton}</div>
        </>
      }
      {/* <MessageModal
        visible={MessageModalStatus}
        onClose={closeMessageModal}
        messageType={currentMessageType}
        app={app}
      /> */}
    </Card>
  );
};

export default JobPostingItem;
