
import React, { useState, useContext, useEffect, useRef } from "react";
import { Tag } from 'antd';
import './style.css'




const KeywordsScroll = ({ keywords }) => {
    const scrollContainerRef = useRef(null);

    const keywordsLength = keywords.length;
    const timePerKeyword = 5000;
    const totalDuration = keywordsLength * timePerKeyword;

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
            let start = null;
            const duration = totalDuration; // Duration of the scroll in milliseconds

            const step = (timestamp) => {
                if (!start) start = timestamp;
                const progress = timestamp - start;
                const scrollAmount = Math.min(progress / duration, 1) * scrollContainer.scrollWidth;
                scrollContainer.scrollLeft = scrollAmount;

                if (progress < duration) {
                    requestAnimationFrame(step);
                }
            };

            requestAnimationFrame(step);
        }
    }, [keywords]);


    return (
        <div ref={scrollContainerRef} className="keyword-scroller">
            {keywords.map((keyword, index) => (
                <Tag key={index} style={{ marginRight: '10px', backgroundColor: '#2ca4cc', color: 'white' }}>{keyword}</Tag>
            ))}
            {/* color:'#001529' */}
            <style jsx>{`
                div::-webkit-scrollbar {
                    display: none;
                }
            `}</style>
        </div>
    );
};


export default KeywordsScroll;