import React, { useState, useEffect } from 'react';
import { Button, Modal, Space, Alert, Collapse, Flex, Progress } from 'antd';
import scoreResult from '../helpers/scoreResult';

const { Panel } = Collapse;


const EvalAnalysis = ({ result, key }) => {

    return (
        <Collapse>
            <Panel
                header={<>
                    {`${result.evaluation} - `}
                    {scoreResult(result)}
                    
                </>
                }
                key={key}
            >
                {/* <Space>
                    <Progress
                        type="circle"
                        percent={Math.round((result.userBoost / result.maxBoost) * 100)}
                        status="active"
                        strokeColor="#fcdc5c"
                        strokeWidth={8}
                    />
                    <Alert
                        message={`Your ${result.evaluation} score is ${result.userBoost} out of ${result.maxBoost}`}
                        type="info"
                    />
                </Space> */}
                <>
                <p>{result.feedback}</p>
                </>
            </Panel>
        </Collapse>
    );
}


export default EvalAnalysis;