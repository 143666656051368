import React, { useState, useEffect, useContext } from 'react';
import { Button, Form, Input, Spin, Select, notification } from 'antd';
import { UserContext } from '../../../contexts/userContext';
import useIsMobile from '../../../contexts/useIsMobile';
import { useMutation } from '@apollo/client';
import { UPDATE_MATCHER_EMAIL } from '../../../utils/mutations';
import { ADD_MATCHER_EMAIL_TO_USER } from '../../../utils/mutations';
import { EDIT_MATCHER_SIGNATURE } from '../../../utils/mutations';

const { Option } = Select;

const MatcherEmails = () => {
    const { user, refetchUser } = useContext(UserContext);
    const [form] = Form.useForm(); // Create a form instance
    const [selectedEmailIndex, setSelectedEmailIndex] = useState(null);
    const [updateMatcherEmail] = useMutation(UPDATE_MATCHER_EMAIL);
    const [addMatcherEmailToUser] = useMutation(ADD_MATCHER_EMAIL_TO_USER);
    const [editMatcherSignature] = useMutation(EDIT_MATCHER_SIGNATURE);
    const [showEditSignature, setShowEditSignature] = useState(false);

    const isMobile = useIsMobile();

    useEffect(() => {
        if (user && user.matcherEmails && selectedEmailIndex !== null) {
            const email = user.matcherEmails[selectedEmailIndex];
            form.setFieldsValue({
                theme: email.theme,
                contactType: email.contactType,
                emailBody: email.emailBody,
                emailSubject: email.emailSubject,
                timesSent: email.timesSent,
                timesReplied: email.timesReplied,
                keywords: email.keywords,
            });
        }

        if (user && user.matcherSignature) {
            form.setFieldsValue({
                fullName: user.matcherSignature.fullName,
                title: user.matcherSignature.title,
                education: user.matcherSignature.education,
                phone: user.matcherSignature.phone,
                email: user.matcherSignature.email,
                linkedIn: user.matcherSignature.linkedIn,
                github: user.matcherSignature.github,
                portfolio: user.matcherSignature.portfolio,
            });
        }
    }, [user, selectedEmailIndex, form]);

    const handleFormSubmit = (values) => {
        // Handle form submission here
        console.log(`Form values for email ${selectedEmailIndex}:`, values);
    };



    const handleEditSignature = async () => {
        try {
            await editMatcherSignature({
                variables: {
                    id: user._id,
                    input: {
                        fullName: form.getFieldValue('fullName'),
                        title: form.getFieldValue('title'),
                        education: form.getFieldValue('education'),
                        phone: form.getFieldValue('phone'),
                        email: form.getFieldValue('email'),
                        linkedIn: form.getFieldValue('linkedIn'),
                        github: form.getFieldValue('github'),
                        portfolio: form.getFieldValue('portfolio'),
                    }
                },
            });

            // Show success notification
            notification.success({
                message: 'Signature Updated',
                description: 'Your email signature has been successfully updated.',
                placement: 'topRight',
            });
        } catch (error) {
            console.error("Error updating signature:", error);
            // Show error notification
            notification.error({
                message: 'Update Failed',
                description: 'There was an error updating your email signature. Please try again.',
                placement: 'topRight',
            });
        }
    };

    const handleAddNewEmail = async () => {
        try {
            // Handle adding a new email here
            await addMatcherEmailToUser({
                variables: {
                    matcherEmails: {
                        emailSubject: form.getFieldValue('emailSubject'),
                        emailBody: form.getFieldValue('emailBody'),
                        contactType: form.getFieldValue('contactType'),
                        theme: form.getFieldValue('theme'),
                        keywords: form.getFieldValue('keywords'),
                    }
                },
            });

            await refetchUser();

            setSelectedEmailIndex(user.matcherEmails.length - 1);

            // Show success notification
            notification.success({
                message: 'Email Added',
                description: 'The new email has been successfully added.',
                placement: 'topRight',
            });
        } catch (error) {
            console.error("Error adding new email:", error);
            // Show error notification
            notification.error({
                message: 'Add Email Failed',
                description: 'There was an error adding the new email. Please try again.',
                placement: 'topRight',
            });
        }
    };

    const handleUpdateEmail = async () => {
        try {
            // Handle updating an email here
            await updateMatcherEmail({
                variables: {
                    userId: user._id,
                    emailId: user.matcherEmails[selectedEmailIndex]._id,
                    input: {
                        emailSubject: form.getFieldValue('emailSubject'),
                        emailBody: form.getFieldValue('emailBody'),
                        contactType: form.getFieldValue('contactType'),
                        theme: form.getFieldValue('theme'),
                        keywords: form.getFieldValue('keywords'),
                    }
                },
            });

            // Show success notification
            notification.success({
                message: 'Email Updated',
                description: 'The email has been successfully updated.',
                placement: 'topRight',
            });
        } catch (error) {
            console.error("Error updating email:", error);
            // Show error notification
            notification.error({
                message: 'Update Email Failed',
                description: 'There was an error updating the email. Please try again.',
                placement: 'topRight',
            });
        }
    };

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    if (!user || !user.matcherEmails) {
        // Display a loading indicator while waiting for data to load
        return <Spin size="large" />;
    }

    return (
        <div style={{ margin: '10px' }}>
            <h2>Edit Email Details</h2>
            <Select
                style={{ width: 200, marginBottom: 20 }}
                placeholder="Select an email"
                onChange={(value) => setSelectedEmailIndex(value)}
            >
                {user.matcherEmails.map((email, index) => (
                    <Option key={index} value={index}>
                        {email.theme ? email.theme + " -" : ""} Email {index + 1}
                    </Option>
                ))}
            </Select>
            {selectedEmailIndex !== null && (
                <Form
                    {...layout}
                    form={form} // Pass the form instance to the Form component
                    name={`edit-email-${selectedEmailIndex}`}
                    style={{ maxWidth: 600 }}
                >
                    <Form.Item name='theme' label="Theme" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name='contactType' label="Contact Type">
                        <Select>
                            <Select.Option value="Company">Company</Select.Option>
                            <Select.Option value="Individual">Individual</Select.Option>
                            <Select.Option value="Recruiter">Recruiter</Select.Option>
                            <Select.Option value="HiringManager">Hiring Manager</Select.Option>
                            <Select.Option value="Other">Other</Select.Option>
                            <Select.Option value={null}>None</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name='emailSubject' label="Email Subject" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name='emailBody' label="Email Body" rules={[{ required: true }]}>
                        <Input.TextArea rows={4} />
                    </Form.Item>
                    {/* <Form.Item name='timesSent' label="Times Sent" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name='timesReplied' label="Times Replied" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item> */}
                    <Form.Item name='keywords' label="Keywords" rules={[{ required: true }]}>
                        <Select mode="tags" style={{ width: '100%' }} placeholder="Add Keywords">
                            {/* Dynamically generate options based on skills array */}
                        </Select>
                    </Form.Item>
                    <Form.Item wrapperCol={isMobile ? { span: 24 } : { ...layout.wrapperCol, offset: 8 }} style={{ textAlign: 'center' }}>
                        <Button type="primary" onClick={handleUpdateEmail}>
                            Update
                        </Button>
                        <Button type="default" style={{ marginLeft: '10px' }} onClick={handleAddNewEmail}>
                            Add New
                        </Button>
                    </Form.Item>
                </Form>
            )}
            <h2>Edit Signature</h2>
            <Button onClick={() => setShowEditSignature(!showEditSignature)}>Edit Signature</Button>
            {showEditSignature && (
                <Form
                    {...layout}
                    form={form} // Pass the form instance to the Form component
                    name={`edit-signature`}
                    style={{ maxWidth: 600 }}
                >
                    <Form.Item name='fullName' label="Full Name" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name='title' label="Title">
                        <Input />
                    </Form.Item>
                    <Form.Item name='education' label="Education" >
                        <Input />
                    </Form.Item>
                    <Form.Item name='phone' label="Phone">
                        <Input />
                    </Form.Item>
                    <Form.Item name='email' label="Email">
                        <Input />
                    </Form.Item>
                    <Form.Item name='linkedIn' label="LinkedIn" >
                        <Input />
                    </Form.Item>
                    <Form.Item name='github' label="GitHub">
                        <Input />
                    </Form.Item>
                    <Form.Item name='portfolio' label="Portfolio">
                        <Input />
                    </Form.Item>
                    <Form.Item wrapperCol={isMobile ? { span: 24 } : { ...layout.wrapperCol, offset: 8 }} style={{ textAlign: 'center' }}>
                        <Button type="primary" onClick={handleEditSignature}>
                            Update
                        </Button>
                    </Form.Item>
                </Form>
            )}
        </div>
    );
};

export default MatcherEmails;
