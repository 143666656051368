import React, { useEffect, useState, useContext } from 'react';
import JobPostingItem from '../../components/Cards/JobPostingItem/index.js';
import ContactItem from '../../components/Cards/ContactItem/index.js';
import CompanyItem from '../../components/Cards/CompanyItem/index.js';
import LocationSearchBox from '../../components/SearchBoxes/LocationSearchBox/index.js';
import KeywordSearchBox from '../../components/SearchBoxes/KeywordSearchBox/index.js';
import { useQuery } from '@apollo/client';
import { QUERY_ALL_PUBLIC_JOB_POSTINGS } from '../../utils/queries.js';
import { QUERY_PUBLIC_CONTACTS } from '../../utils/queries.js'
import { QUERY_PUBLIC_COMPANIES } from '../../utils/queries.js'
import { Row, Col, Input, Button } from 'antd';
import './Opportunities.css';
import { UserContext } from '../../contexts/userContext.js';


import { useLocation } from 'react-router-dom';

const OpportunitiesList = () => {
  const location = useLocation();
  const { loading, error, data, refetch } = useQuery(QUERY_ALL_PUBLIC_JOB_POSTINGS);
  const [jobPostings, setJobPostings] = useState([]);
  const [filteredJobPostings, setFilteredJobPostings] = useState([]);
  const { data: contactData } = useQuery(QUERY_PUBLIC_CONTACTS);
  const [contacts, setContacts] = useState([]);
  const { data: companyData } = useQuery(QUERY_PUBLIC_COMPANIES);
  const [companies, setCompanies] = useState([]);
  const { user } = useContext(UserContext);

  const [searchTerms, setSearchTerms] = useState({ keyword: '', location: '' });

  useEffect(() => {
    if (location.pathname === '/find-jobs') {
      console.log('refetching');
      refetch();
    }
  }, [location.pathname, refetch]);

  useEffect(() => {
    if (data && user && user._id) {

      const jobPostings = data.publicJobPostings.filter(job =>
        job.recommendedTo.some(recUser => recUser._id === user._id)
      );

      setJobPostings(jobPostings);
      setFilteredJobPostings(jobPostings);
    }
    if (contactData && user && user._id) {
      setContacts(
        contactData.publicContacts.filter(contact =>
          contact.recommendedTo.some(recUser => recUser._id === user._id)
        )
      );
    }
    if (companyData && user && user._id) {
      setCompanies(
        companyData.publicCompanies.filter(company =>
          company.recommendedTo.some(recUser => recUser && recUser._id === user._id)
        )
      );
    }
  }, [data, contactData, companyData]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;


  const handleSearch = () => {
    // Combine keyword and location to perform a search
    const { keyword, location } = searchTerms;

    // Validate and format the location input
    const formattedLocation = formatLocation(location);

    const newFilteredJobPostings = jobPostings.filter((job) =>
      job.position.toLowerCase().includes(keyword.toLowerCase()) &&
      job.location.toLowerCase().includes(formattedLocation.toLowerCase())
    );

    setFilteredJobPostings(newFilteredJobPostings);

    //setSearchTerms({location: ' '}) - Maybe Not Neccessary

    // Handle filteredJobPostings (e.g., display results or take further action)
    console.log(keyword, formattedLocation, newFilteredJobPostings);
  };

  // Function to format the location input
  const formatLocation = (inputLocation) => {
    // Split the input by commas and trim whitespace
    const parts = inputLocation.split(',').map((part) => part.trim());

    // Check if the input has at least two parts (City and Province/State)
    if (parts.length >= 2) {
      // Capitalize the first letter of each part and join them with a comma
      return parts.map((part) => part.charAt(0).toUpperCase() + part.slice(1)).join(', ');
    } else {
      // Invalid input format, return the original input
      return inputLocation;
    }
  };

  const handleAllJobs = () => {
    setFilteredJobPostings(jobPostings);
  }


  return (
    <div>
      {/* Keyword and Location Input Fields on the same row */}

      {/* <div className="search-box">
        <KeywordSearchBox
          onSearch={(keyword) => {
            setSearchTerms((prevSearchTerms) => ({ ...prevSearchTerms, keyword }));
          }}
        />
        <LocationSearchBox onSearch={(location) => setSearchTerms({ ...searchTerms, location })} />

        <Button type="primary" onClick={handleSearch}>
          Search
        </Button>
        <Button type="primary" onClick={handleAllJobs}>
          All Jobs
        </Button>
      </div> */}

      <Row gutter={[16, 16]}>
        {filteredJobPostings.map((job, index) => (
          <Col xs={24} sm={12} md={8} lg={8} xl={8} key={index}>
            <JobPostingItem job={job} />
          </Col>
        ))}
        {contacts.map((contact, index) => (
          <Col xs={24} sm={12} md={8} lg={8} xl={8} key={index}>
            <ContactItem contact={contact} />
          </Col>
        ))}
        {companies.map((company, index) => (
          <Col xs={24} sm={12} md={8} lg={8} xl={8} key={index}>
            <CompanyItem company={company} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default OpportunitiesList;


