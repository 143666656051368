import React, { useEffect, useState, useContext } from 'react';
import { useQuery } from '@apollo/client'; // Import the appropriate query hook
import { QUERY_MY_APPLICATIONS } from '../../../utils/queries.js'
import { QUERY_MY_CONTACTS } from '../../../utils/queries.js';
import { QUERY_MY_COMPANIES } from '../../../utils/queries.js';
import { QUERY_ME } from '../../../utils/queries.js';
import { Avatar, Button, List, Skeleton, Card, Checkbox } from 'antd';
import { ReloadOutlined, StarFilled, CalendarFilled, CheckSquareFilled, MailFilled, ThunderboltFilled, ExclamationCircleFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import ToDoGrid from './ToDoGrid';
import { UserContext } from '../../../contexts/userContext';




const RecommendToDos = () => {
    const { loading: loadingApps, data: myApps, refetch: refetchApps } = useQuery(QUERY_MY_APPLICATIONS, { fetchPolicy: 'network-only' });
    const { loading: loadingContacts, data: myContacts, refetch: refetchContacts } = useQuery(QUERY_MY_CONTACTS, { fetchPolicy: 'network-only' });
    const { loading: loadingCompanies, data: myCompanies, refetch: refetchCompanies } = useQuery(QUERY_MY_COMPANIES, { fetchPolicy: 'network-only' });
    const { loading: loadingMe, data: me, refetch: refetchGoals } = useQuery(QUERY_ME, { fetchPolicy: 'network-only' });
    const [applications, setApplications] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [applicationGoals, setApplicationGoals] = useState([]);
    const [contactGoals, setContactGoals] = useState([]);
    const [resumeData, setResumeData] = useState([]);
    const { allMyEmails } = useContext(UserContext);
    const [unReadEmails, setUnReadEmails] = useState(0);

    const [elements, setElements] = useState([]);

    //console.log(me);

    useEffect(() => {
        if (myApps) {
            setIsLoading(false);
            setApplications(myApps.myApplications);
        }
        if (myContacts) {
            setIsLoading(false);
            setContacts(myContacts.myContacts);
        }
        if (myCompanies) {
            setIsLoading(false);
            setCompanies(myCompanies.myCompanies);
        }
        if (me) {
            setIsLoading(false);
            setApplicationGoals(me.user.goals.applications.weeklyApplyGoal);
            setContactGoals(me.user.goals.networking.weeklyNetworkingGoal);
            setResumeData(me.user.myResumeData);
        }
        if (allMyEmails) {
            setUnReadEmails(allMyEmails.filter(email => email.read === false).length);
        }
    }, [myApps, myContacts, myCompanies, me, allMyEmails]);

    const refreshData = () => {
        refetchApps();
        refetchContacts();
        refetchCompanies();
        refetchGoals();
    }

    // console.log(applications);
    // console.log(contacts);
    // console.log(me);


    //------Reused Totals-------
    //Total Number of Contacts
    const totalContacts = contacts.length;
    //Total Number of Applications
    const totalApplications = applications.length;
    //Total Number of Companies
    const totalCompanies = companies.length;
    //Total Number of Contacted Contacts
    const contactedContacts = contacts.filter(contact => contact.lastContacted !== null).length;
    //Total Number of Contacted Companies
    const contactedCompanies = companies.filter(company => company.lastContacted !== null && company.linkedIndvContact == null).length;
    //Total Number of Saved Applications
    const savedApplications = applications.filter(application => application.status === "Saved").length;
    //Total Number of Recent Applications (Status is "Saved" or "Applied" and dateApplied is within the last 7 days)
    const recentApplications = applications.filter(application => (application.status === "Saved" || application.status === "Applied") && application.dateApplied >= Date.now() - 604800000).length;
    //Total Number of Archived Applications
    const archivedApplications = applications.filter(application => application.status === "Archived").length;


    //----------Application Specific Totals---------
    //Get Number of Applications Applied to Today
    const appliedToday = applications.filter(application => application.dateApplied === Date.now()).length;
    //Get Number of Favourited Applications && Status = Saved
    const favouritedSavedApplications = applications.filter(application => application.favorite === true && application.status === "Saved").length;
    //Get Number of Applications Where Status = "Interviewing"
    const interviewingApplications = applications.filter(application => application.status === "Interviewing").length;
    //Get Number of Applications Where Status = Applied & dateFollowedUp = null & dateApplied >= 7 days ago
    const appliedNoFollowUp = applications.filter(application => application.status === "Applied" && application.dateFollowedUp === null && application.dateApplied <= Date.now() - 604800000).length;
    //Get Number of Applications Where Status = Applied OR Saved AND dateNetworked = null
    const noNetwork = applications.filter(application => (application.status === "Saved" || application.status === "Applied") && application.dateNetworked === null).length;
    //Get Number of Saved Applications Where Optimized Resume = false
    const unoptimizedResume = applications.filter(application => application.resumeOptimized === false && application.status === "Saved" && application.favorite === true).length;
    //Get Number of Saved Applications Where Optimized Cover Letter = false
    const unoptimizedCoverLetter = applications.filter(application => application.coverletterOptimized === false && application.status === "Saved").length;
    //Get Number of Apps Where An Interview Was Recieved
    const recievedInterview = applications.filter(application => application.recievedInterview === true).length;
    //Get Number of Applications Where You requested Feedback
    const requestedFeedback = applications.filter(application => application.dateRequestedFeedback !== null).length;


    //---------Contact Specific Totals---------
    //Get Number of Contacts Contacted Today - Individuals
    const contactedToday = contacts.filter(contact => contact.lastContacted === Date.now()).length;
    //Get Number of Favourited Contacts - Individuals
    const favouritedContacts = contacts.filter(contact => contact.favorite === true).length;
    //Get Number of Contacts Where lastContacted is at least 2 days ago AND lastfollowUp is null AND replied = false - Individuals
    const noReplies = contacts.filter(contact => contact.lastContacted <= Date.now() - 172800000 && contact.lastfollowUp === null && contact.replied === false && contact.lastContacted !== null).length;
    //Get Number of Contacts Where lastContacted or lastfollowUp (Whichever is more recent) is older than 7 days AND replied = false - Individuals
    const noReplies7Days = contacts.filter(contact => (contact.lastContacted <= Date.now() - 604800000 || contact.lastfollowUp <= Date.now() - 604800000) && contact.replied === false && contact.lastContacted !== null && contact.lastfollowUp !== null).length;

    //Get Number of Companies Where lastContacted is at least 2 days ago AND lastfollowUp is null AND replied = false - Companies
    const noRepliesCompanies = companies.filter(company => company.lastContacted <= Date.now() - 172800000 && company.lastfollowUp === null && company.replied === false && company.lastContacted !== null && company.linkedIndvContact == null).length;



    useEffect(() => {
        const newElements = [];

        //Explore Opportinities & Meet Application Goal
        // if (savedApplications == 0) {
        //     newElements.push({ key: 'TEMP', text: `Explore/Save Some Potential Job Postings` });
        // }
        // if (savedApplications < (applicationGoals - appliedToday)) {
        //     newElements.push({ key: 'apply', text: `Explore/Save Some Potential Job Postings` });
        // } // Make this next element and else if? to avoid showing both elements
        // else if (appliedToday < applicationGoals) {
        //     newElements.push({ key: 'apply', text: `Meet your daily goal by applying to ${applicationGoals - appliedToday} Jobs` });
        // }

        //Explore Contacts & Meet Networking Goal
        // if (totalContacts == 0) {
        //     newElements.push({ key: 'TEMP', text: `Explore/Save Some Potential Contacts` });
        // } 
        // if (totalContacts <= (contactGoals - contactedContacts)) {
        //     newElements.push({ key: 'network', text: `Explore/Save Some Potential Contacts` });
        // } else if (contactedToday < contactGoals) {
        //     newElements.push({ key: 'network', text: `Meet your daily goal by networking with ${contactGoals - contactedToday} Contacts` });
        // }

        //If No Resume Data
        if (!resumeData) {
            newElements.push(
                {
                    priority: 101, key: 'noResumeData', text: <span style={{ color: '#2ca4cc' }}>Add your resume</span>, icon: <ExclamationCircleFilled style={{ color: '#2ca4cc' }} />, link: '/profile/resume', linkClass: 'todo-pulse',
                    description: "Upload your resume to unlock additional features such as resume optimization and tracking."
                }
            );
        }

        //--------Application Specific To Dos--------
        //If No Applications
        if (totalApplications === 0) {
            newElements.push({ 
                priority: 100, key: 'noApplications', text: <span style={{ color: '#2ca4cc' }}>Add your first application</span>, icon: <ExclamationCircleFilled style={{ color: '#2ca4cc' }} />, link: '/applications', linkClass: 'todo-pulse',
                description: "Add your first application to start tracking your job search progress."
                });
        }

        //Favourite Applications
        if (favouritedSavedApplications / savedApplications < 0.2) {
            //MIN 10 Saved Applications
            if (savedApplications > 10) {
                newElements.push({ 
                    priority: 90, key: 'favourited', text: `Favourite some saved applications of high interest`, icon: <StarFilled />, link: '/applications?filter=Saved',
                    description: "Favouriting applications can help you keep track of your most important job postings."
                 });
            }
        }

        //Interviewing Applications
        if (interviewingApplications > 0) {
            newElements.push({ 
                priority: 99, key: 'interviewing', text: <span style={{ color: '#2ca4cc' }}>Prepare for your upcoming interviews</span>, icon: <CalendarFilled style={{ color: '#2ca4cc' }} />, link: '/applications?filter=Interviewing', linkClass: 'todo-pulse',
                description: "Preparation is key to a successful interview."
            });
        }

        //Follow Up With Applications
        if (appliedNoFollowUp > 0) {
            newElements.push({ 
                priority: 70, key: 'followUp', text: <span style={{ color: '#2ca4cc' }}>Follow Up With {appliedNoFollowUp} Applications</span>, icon: <MailFilled style={{ color: '#2ca4cc' }} />, link: '/applications?filter=Applied&followedup=no', linkClass: 'todo-pulse',
                description: "Following up with applications increases the chance of a response by up to 30%"
            });
        }

        //Network With Applications
        if (noNetwork > 0) {
            newElements.push({
                priority: 65, key: 'network', text: `Network With ${noNetwork} Job Postings`, icon: <MailFilled />, link: '/applications?filter=Saved&networked=no',
                description: "Those who network are 2-3 times more likely to land the role"
            });
        }

        //Optimize Resume
        if (unoptimizedResume > 0) {
            newElements.push({
                priority: 60, key: 'resume', text: `Optimize ${unoptimizedResume} Resume(s) for your favorited jobs`, icon: <ThunderboltFilled />, link: '/applications?filter=Saved&&resumeoptimized=no&&fav=true',
                description: "An optimized resume can increase your chances of landing an interview by up to 40%"
            });
        }

        //Optimize Cover Letter
        if (unoptimizedCoverLetter > 0) {
            newElements.push({
                priority: 55, key: 'coverLetter', text: `Create ${unoptimizedCoverLetter} Cover Letter(s)`, icon: <ThunderboltFilled />, link: '/applications?filter=Saved&&cloptimized=no',
                description: "A tailored cover letter can increase your chances of landing an interview by up to 20%"
            });
        }

        //May need to improve your application strategy
        // if (recievedInterview / (totalApplications - recentApplications) < 0.1) {
        //     if (totalApplications - recentApplications > 50) {
        //         newElements.push({
        //             priority: 94, key: 'recievedInterview', text: 'Looks like you are not receiving many interviews. View some tips how to improve your application strategy.', icon: <ExclamationCircleFilled />,
        //             description: "You may need to improve your application strategy to increase your chances of landing an interview."
        //         });
        //     }
        // }

        //Request for Feedback
        if (requestedFeedback / archivedApplications < 0.25) {
            if (archivedApplications > 20) {
                newElements.push({
                    priority: 50, key: 'requestedFeedback', text: `Request Feedback for on past Applications`, icon: <ExclamationCircleFilled />,
                    description: "Requesting feedback can help you understand why you were not selected for a role and how to improve for future applications."
                });
            }
        }

        //--------------Contact Specific To Dos----------------
        //Favourite Contacts - Individuals
        if (favouritedContacts / totalContacts < 0.2) {
            //MIN 10 Contacts
            if (totalContacts > 10) {
                newElements.push({
                    priority: 69, key: 'favourited', text: `Favourite some contacts of high interest`, icon: <StarFilled />, link: '/contacts',
                    description: "Favouriting contacts can help you keep track of your most important connections."
                });
            }
        }

        //Follow Up With Contacts - Individuals
        if (noReplies > 0) {
            newElements.push({
                priority: 75, key: 'noReplies', text: <span style={{ color: '#2ca4cc' }}>Follow Up With {noReplies} Contacts</span>, icon: <MailFilled style={{ color: '#2ca4cc' }} />, link: '/contacts?followedUp=no&&replied=no&&contacted=yes&&filter=false%%&&contactDateOlderThan=2', linkClass: 'todo-pulse',
                description: "Following up with contacts increases the chance of a response by up to 40%"
            });
        }

        // May need to Improve your networking efforts - Individuals
        // if (noReplies7Days / contactedContacts > 0.5) {
        //     newElements.push({
        //         priority: 95, key: 'noReplies7Days', text: 'Looks like you are receiving delayed replies. View some tips how to improve your response rate.', icon: <ExclamationCircleFilled />,
        //         description: "You may need to improve your networking efforts to increase your chances of receiving a response."
        //     });
        // }

        //Follw Up With Companies
        if (noRepliesCompanies > 0) {
            newElements.push({
                priority: 75, key: 'noRepliesCompanies', text: <span style={{ color: '#2ca4cc' }}>Follow Up With {noRepliesCompanies} Companies</span>, icon: <MailFilled style={{ color: '#2ca4cc' }} />, link: '/contacts?cfollowUp=no&&creplied=no&&ccontacted=yes&&ccontactDateOlderThan=2', linkClass: 'todo-pulse',
                description: "Following up with contacts increases the chance of a response by up to 40%"
            });
        }

        //Unread Emails
        if (unReadEmails > 0) {
            newElements.push({
                priority: 100, key: 'unreadEmails', text: <span style={{ color: '#2ca4cc' }}>You have {unReadEmails} unread email(s)</span>, icon: <MailFilled style={{ color: '#2ca4cc' }} />, link: '/inbox', linkClass: 'todo-pulse',
                description: "You got mail! Check your inbox for important updates."
            });
        }

        // Sort the elements by priority in descending order
        newElements.sort((a, b) => b.priority - a.priority);

        setElements(newElements);
    }, [applications, contacts, applicationGoals, contactGoals, companies, allMyEmails]);


    if (isLoading) {
        return <div>Loading...</div>;
    }


    return (
        // <Card title={
        //     <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        //         <span>To Dos</span>
        //         {/* <Button icon={<ReloadOutlined />} onClick={refreshData} /> */}
        //     </div>
        // }>
        //     <List
        //         itemLayout="horizontal"
        //         pagination={{
        //             pageSize: 5,
        //             position: 'bottom',
        //             align: 'end'
        //         }}
        //         dataSource={elements}
        //         renderItem={item => (
        //             <List.Item actions={[<Link to={item.link}><p className={item.linkClass} style={{fontSize: '1em'}}>View</p></Link>]}>
        //                 <List.Item.Meta
        //                     avatar={item.icon ? item.icon : <CheckSquareFilled />}
        //                     title={item.text}

        //                 />

        //             </List.Item>
        //         )}
        //         style={{ minHeight: '300px' }}
        //     />
        // </Card>

        <ToDoGrid elements={elements} />
    )
}

export default RecommendToDos;

// To Do Ideas

