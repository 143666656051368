import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client'; // Import the appropriate query hook
import { QUERY_MY_APPLICATIONS } from '../../../utils/queries.js'
import { QUERY_MY_CONTACTS } from '../../../utils/queries.js';
import { QUERY_MY_COMPANIES } from '../../../utils/queries.js';

import { Row, Col, Card, Divider, Descriptions } from 'antd';

const rowStyle = { marginBottom: '-10px' };

const MainStats = () => {
    const { data: myApps } = useQuery(QUERY_MY_APPLICATIONS);
    const { data: myContacts } = useQuery(QUERY_MY_CONTACTS);
    const { data: myCompanies } = useQuery(QUERY_MY_COMPANIES);
    const [applications, setApplications] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (myApps) {
            setIsLoading(false);
            setApplications(myApps.myApplications);
        }
        if (myContacts) {
            setIsLoading(false);
            setContacts(myContacts.myContacts);
        }
        if (myCompanies) {
            setIsLoading(false);
            setCompanies(myCompanies.myCompanies);
        }
    }, [myApps, myContacts, myCompanies]);

    //Total Number of Applications
    const totalApplications = applications.length;
    //Total Number of Applies
    const totalApplies = applications.filter(application => application.dateApplied !== null).length;
    //Total Number of Interviews
    const totalInterviews = applications.filter(application => application.recievedInterview === true).length;
    //Total Number of Offers
    const totalOffers = applications.filter(application => application.recievedOffer === true).length;
    //Total Number of Resumes Optimized
    const totalResumesOptimized = applications.filter(application => application.resumeOptimized === true).length;
    //Total Number of Cover Letters Optimized
    const totalCoverLettersOptimized = applications.filter(application => application.coverletterOptimized === true).length;

    //Total Number of Contacts
    const totalContacts = contacts.length;
    //Total Number of Companies
    const totalCompanies = companies.length;
    //Total Number of Networked Contacts
    const totalNetworkedContacts = contacts.filter(contact => contact.lastContacted !== null).length;
    //Total Number of Networked Companies
    const totalNetworkedCompanies = companies.filter(company => company.lastContacted !== null && company.linkedIndvContact == null).length;
    //Total Number of Replies
    const totalReplies = contacts.filter(contact => contact.replied === true).length;
    //Total Number of Replies from Companies
    const totalRepliesCompanies = companies.filter(company => company.replied === true).length

    return (
        <div>
            <Card title="Job Search Statistics"  bodyStyle={{ padding: '5px' }}>
                <Descriptions bordered column={1} style={{ padding: '0', margin: '0', whiteSpace: 'nowrap' }}>
                    <Descriptions.Item label="Applications">{totalApplications}</Descriptions.Item>
                    <Descriptions.Item label="Applies">{totalApplies}</Descriptions.Item>
                    <Descriptions.Item label="Interviews">{totalInterviews}</Descriptions.Item>
                    <Descriptions.Item label="Offers">{totalOffers}</Descriptions.Item>
                    <Descriptions.Item label="Resumes Optimized">{totalResumesOptimized}</Descriptions.Item>
                    <Descriptions.Item label="Cover Letters Optimized">{totalCoverLettersOptimized}</Descriptions.Item>
                    <Descriptions.Item label="Contacts">{totalContacts + totalCompanies}</Descriptions.Item>
                    <Descriptions.Item label="Networked Contacts">{totalNetworkedContacts + totalNetworkedCompanies}</Descriptions.Item>
                    <Descriptions.Item label="Replies">{totalReplies + totalRepliesCompanies}</Descriptions.Item>
                </Descriptions>
            </Card>
        </div>
    );
}

export default MainStats;
