import { gql } from '@apollo/client';

//----------------------------------------------------------User Related Queries----------------------------------------------------------//
export const QUERY_ME = gql`
query User {
  user {
    _id
    firstName
    lastName
    email
    phone
    workHistory {
      jobTitle
      company
      location {
        city
        provinceOrState
      }
      currentlyWorking
      startDate
      endDate
      description
    }
    education {
      school
      degree
      fieldOfStudy
      currentlyAttending
      startDate
      endDate
      gpa
      description
    }
    resume {
      filename
      url
      mimeType
    }
    myResumeData {
      _id
    }
    savedResumes {
      resumeData {
        _id
      }
      resumeName
    }
    myCoverLetterData {
      _id
    }
    coverLetter {
      filename
      url
      mimeType
    }
    gmail {
      email
      accessToken
      refreshToken
    }
    preference {
      industry
      city
      worksite
      provinceOrState
      desiredSalary
      partOrFullTime
      currentJob
      currentJobSearchStatus
      desiredJob {
        jobTitle
        jobdescription1
        jobdescription2
        jobdescription3
        jobdescription4
        jobdescription5
      }
    }
    socialURLs {
      linkedIn
      github
      portfolio
    }
    skills
    currentJobStrategyStep
    jobStrategy {
      intialJobAdvice
      targetJobPersonalAdvice
    }
    goals {
      applications {
        weeklyApplyGoal
      }
      networking {
        weeklyNetworkingGoal
      }
    }
    stripeCustomerId
    subscriptions
    customUserEmail
    targetJob {
      _id
      title
      jobFields {
        _id
        Field
      }
    }
    
    yearsExperience
    targetJobLevel
    targetCompanies {
      _id
      companyName
    }
    keywords
    targetLocation {
      city
      countryCode
      officePreference
      openToRelocation
      provinceOrState
    }
    matcherEmails {
      _id
      theme
      contactType
      emailBody
      emailSubject
      timesSent
      timesReplied
      keywords
    }
    matches {
      matchesFound {
        thisWeek
        total
      }
      matchesLiked
    }
    matcherSignature {
      education
      email
      fullName
      github
      linkedIn
      phone
      portfolio
      title
    }
  }
}
`;

export const QUERY_MY_RESUME_DATA = gql`
query User {
  user {
    myResumeData {
      mainData {
        basics {
          name
          label
          image
          email
          phone
          url
          summary
          location {
            address
            postalCode
            city
            countryCode
            region
          }
          profiles {
            network
            username
            url
          }
        }
        work {
          position
          name
          location
          startDate
          endDate
          currentlyWorking
          url
          highlights
        }
        education {
          school
          degree
          fieldOfStudy
          currentlyAttending
          startDate
          endDate
          gpa
          description
          studyType
          area
          institution
          location
          score
          courses
          highlights
        }
        projects {
          name
          highlights
          url
          startDate
          endDate
        }
        certifications {
          name
          issuer
          date
          url
        }
        awards
        publications {
          name
          publisher
          releaseDate
          url
          summary
        }
        skills
        languages {
          language
          fluency
        }
        interests
        volunteer {
          organization
          position
          url
          startDate
          endDate
          summary
          highlights
        }
        otherActivities {
        endDate
        highlights
        location
        name
        position
        startDate
        url
      }
        id
      }
    }
  }
}
`;

export const QUERY_MY_SAVED_RESUMES = gql`
query User {
  user {
    savedResumes {
      resumeName
      resumeData {
        _id
      }
    }
  }
}
`;

//----------------------------------------------------------Resume Data Related Queries----------------------------------------------------------//
export const QUERY_RESUME_DATA_BY_ID = gql`
query Query($id: ID!) {
  getResumeDataByID(_id: $id) {
    mainData {
      awards
      basics {
        name
        label
        image
        email
        phone
        url
        summary
        location {
          address
          postalCode
          city
          countryCode
          region
        }
        profiles {
          network
          username
          url
        }
      }
      certifications {
        date
        issuer
        name
        url
      }
      education {
        school
        degree
        fieldOfStudy
        currentlyAttending
        startDate
        endDate
        gpa
        description
        studyType
        area
        institution
        location
        score
        courses
        highlights
      }
      id
      interests
      languages {
        language
        fluency
      }
      projects {
        name
        highlights
        url
        startDate
        endDate
      }
      publications {
        name
        publisher
        releaseDate
        url
        summary
      }
      skills
      volunteer {
        organization
        position
        url
        startDate
        endDate
        summary
        highlights
      }
      work {
        position
        name
        location
        startDate
        endDate
        currentlyWorking
        url
        highlights
      }
      otherActivities {
        endDate
        highlights
        location
        name
        position
        startDate
        url
      }
    }
    theme
  }
}
`;

export const QUERY_MY_RESUMES_BY_USER_ID = gql`
query GetAllResumeDataByUserID($createdBy: ID) {
  getAllResumeDataByUserID(createdBy: $createdBy) {
    _id
    saved
    createdAt
    theme
    dataType
    savedName
  }
}
`;

//----------------------------------------------------------Cover Letter Data Related Queries----------------------------------------------------------//
export const QUERY_COVER_LETTER_BY_ID = gql`
query GetCoverLetterDataByID($id: ID!) {
  getCoverLetterDataByID(_id: $id) {
    _id
    coverLetterParagraphs
    currentDate
    jobData {
      company
      hiringManager
      jobTitle
      location
    }
    userData {
      email
      label
      name
      phone
    }
  }
}
`;

//----------------------------------------------------------Contact Related Queries----------------------------------------------------------//
export const QUERY_MY_CONTACTS = gql`
query MyContacts {
  myContacts {
    _id
    user {
      _id
      firstName
    }
    contactDetails {
      _id
      firstName
      lastName
      company {
        companyName
        _id
      }
      jobTitle
      linkedIn
      email
      phone
      dateAdded
      postingStatus
    }
    relationship
    notes
    relatedJobPosting {
      company
      position
    }
    lastContacted
    lastContactMethod
    lastContactMessage
    lastfollowUp
    lastfollowUpMessage
    lastfollowUpMethod
    replied
    repliedDate
    repliedMessage
    meeting {
      date
      start
      end
    }
    favourite
  }
}
`;

export const QUERY_MY_CONTACT_NAMES = gql`
query MyContacts {
  myContacts {
    _id
    contactDetails {
    firstName
    lastName
    company {
        _id
        companyName
      }
    }
  }
}
`;

export const QUERY_PUBLIC_CONTACTS = gql`
query PublicContacts {
  publicContacts {
    firstName
    lastName
    company {
      companyName
    }
    jobTitle
    linkedIn
    dateAdded
    recommendedTo {
      _id
    }
    postingStatus
    _id
  }
}
`;

export const QUERY_CONTACTS_BY_COMPANY_ID = gql`
query FindContactsByCompany($company: ID!) {
  findContactsByCompany(company: $company) {
    _id
    firstName
    lastName
    email
    jobTitle
    department
    hunterConfidence
  }
}
`;
//----------------------------------------------------------Company Related Queries----------------------------------------------------------//
export const QUERY_MY_COMPANIES = gql`
query MyCompanies {
  myCompanies {
    _id
    user {
      _id
      firstName
      lastName
    }
    companyDetails {
      _id
      companyName
      companyWebsite
      companyLinkedIn
      companyEmail
      companyPhone
      companyAddress
      companyCity
      companyStateOrProvince
      industry
      size
      yearFounded
      logo
      recommendedTo {
        _id
      }
      postingStatus
      crunchbaseURL
      companyGlassdoorUrl
      companyGlassdoorRating
      companyGlassdoorVotes
    }
    notes
    lastContacted
    lastContactMethod
    lastContactMessage
    favourite
    lastfollowUp
    lastfollowUpMessage
    lastfollowUpMethod
    replied
    repliedDate
    repliedMessage
    linkedIndvContact {
      _id
    }
  }
}
`;

export const QUERY_ONLY_MY_COMPANIES_IDS = gql`
query MyCompanies {
  myCompanies {
    companyDetails {
      _id
    }
  }
}
`;

export const QUERY_COMPANY_BY_NAME = gql`
query SingleCompanyByName($companyName: String!) {
  singleCompanyByName(companyName: $companyName) {
    _id
  }
}
`;

export const QUERY_PUBLIC_COMPANIES = gql`
query Query {
  publicCompanies {
    _id
    companyName
    description
    companyWebsite
    companyLinkedIn
    companyEmail
    companyPhone
    companyAddress
    companyCity
    companyStateOrProvince
    industry
    size
    yearFounded
    logo
    dateAdded
    recommendedTo {
      _id
    }
    postingStatus
    jobFields {
      _id
    }
    keywords
    locations
    linkedInUpdates
    crunchbaseURL
    companyGlassdoorUrl
    companyGlassdoorRating
    companyGlassdoorVotes
  }
}
`;
//----------------------------------------------------------Job Related Queries----------------------------------------------------------//
export const QUERY_ALL_JOBS = gql`
query jobs {
    allJobs {
        _id
        title
        levels {
            level
            range {
                min
                max
            }
        }
        jobFields {
            Field
            _id
            Keywords
          }
    }
}
`;

//----------------------------------------------------------Job Posting Related Queries----------------------------------------------------------//

export const QUERY_ALL_PUBLIC_JOB_POSTINGS = gql`
query Query {
  publicJobPostings {
    _id
    parsedDescription
    postDate
    formattedPostDate
    deadline
    remote
    city
    provinceOrState
    country
    company
    position
    link
    hiringManager
    email
    description
    experienceLevel
    educationLevel
    category
    salary
    partOrFullTime
    logo
    recommendedTo {
      _id
    }
    status
    location
    notInterestedTo {
      _id
    }
    keywords
    coreSignalID
  }
}
`;

export const QUERY_SINGLE_JOB_POSTING = gql`
query Query($id: ID!) {
  singleJobPosting(_id: $id) {
    _id
    parsedDescription
    postDate
    remote
    location
    company
    position
    link
    salary
    partOrFullTime
    logo
    description
  }
}
`;
//----------------------------------------------------------Application Related Queries----------------------------------------------------------//
export const QUERY_MY_APPLICATIONS = gql`
query MyApplications {
  myApplications {
    _id
    job {
      _id
      company
      position
      link
      salary
      partOrFullTime
      remote
      location
      formattedPostDate
      logo
    }
    status
    resumeOptimized
    coverletterOptimized
    dateApplied
    dateNetworked
    dateFollowedUp
    dateThankYouSent
    dateAcceptedOffer
    dateDeclinedOffer
    dateRequestedFeedback
    recievedInterview
    dateInterviewScheduled
    rejected
    dateArchived
    recievedOffer
    favorite
    reviewedNegotiationTips
    interviewPreped
  }
}
`;

export const QUERY_ONLY_MY_APPLICATIONS_IDS = gql`
query MyApplications {
  myApplications {
    job {
      _id
    }
  }
}
`;

export const QUERY_APPLICATIONS_BY_STATUS = gql`
query GetApplicationsBasedOnStatus($status: String!) {
  getApplicationsBasedOnStatus(status: $status) {
    _id
    job {
      _id
      company
      position
      link
      salary
      partOrFullTime
      remote
      location
      formattedPostDate
      logo
    }
    status
    dateApplied
    dateFollowedUp
  }
}
`;

export const QUERY_JOBS_SAVED = gql`
query GetApplicationByJobID($id: ID!) {
  getApplicationByJobID(_id: $id) {
    status
    success
  }
}
`;

export const QUERY_APPLICATIONS_RESUME_ID = gql`
query GetApplicationByID($id: ID!) {
  getApplicationByID(_id: $id) {
    resumeID {
      _id
    }
  }
}
`;

export const QUERY_APPLICATIONS_COVERLETTER_ID = gql`
query GetApplicationByID($id: ID!) {
  getApplicationByID(_id: $id) {
    coverletterID {
      _id
    }
  }
}
`;

//----------------------------------------------------------Job Field Related Queries----------------------------------------------------------//

export const QUERY_ALL_JOB_FIELDS = gql`
query AllJobFields {
  allJobFields {
    Field
    _id
    Keywords
    Parent
  }
}
`;

//----------------------------------------------------------Email Related Queries----------------------------------------------------------//

export const QUERY_MY_EMAILS = gql`
query GetEmailsByUserID {
  getEmailsByUserID {
    _id
    body
    createdAt
    direction
    fromEmail
    read
    subject
    toEmail
    updatedAt
    userContactId {
      _id
    }
    userId {
      _id
    }
  }
}
`;


//----------------------------------------------------------Evaluation Report Related Queries----------------------------------------------------------//

export const QUERY_MY_EVALUATION_REPORT = gql`
  query Query($userId: ID!) {
  getEvaluationReportByUserID(userId: $userId) {
    _id
    hide
    jobFieldId
    lastEvaluated
    maxScore
    resumeId
    status
    userId
    yourScore
    results {
      category
      evalMethod
      evaluation
      feedback
      hide
      maxBoost
      required
      status
      userBoost
      userDataEvaluated
    }
  }
}
`;



