import axios from 'axios';


//Background
export const startEvaluateTask = async (userID) => {

    const data = {
        userId: userID
    };

    try {

        const response = await axios.post('/evaluate/evaluate-candidacy-score-task', data);

        return response;

    } catch (error) {

        console.error("There was a problem evaluating your matches", error.message);

    }
}

export const getEvaluateTaskStatus = async (taskId) =>{

    try {

        const response = await axios.get(`/evaluate/evaluate-candidacy-score-task/${taskId}/status`);

        return response;

    } catch (error) {

        console.error("There was a problem getting the status of your matches", error.message);

    }
}

export const getEvaluateTaskResult = async (taskId) => {

    try {

        const response = await axios.get(`/evaluate/evaluate-candidacy-score-task/${taskId}/result`);

        return response;

    } catch (error) {

        console.error("There was a problem getting the result of your matches", error.message);

    }
}