import React from "react";
import { Card, Row, Col, Button } from "antd";
import useIsMobile from "../../contexts/useIsMobile";

const PricingTable = () => {

    const isMobile = useIsMobile();

    const pricingData = [
        {
            title: "Basic",
            price: "Forever Free",
            features: [
                "Job Tracker",
                "Professional Relationship Manager",
                "Customized Application Guidance",
                "Customized Job Search Guidance",
                "Resume Builder & Manager"
            ],
            buttonText: "Choose Basic",
        },
        {
            title: "Standard",
            price: <><del>$25/month</del> Free</>,
            features: [
                "Everything in Basic",
                "AI Resume & Cover Letter Generator",
                "Network Message Generator",
                "Basic Candidacy Evaluation",
                "25 Premium Opportunities/Week"
            ],
            buttonText: "Choose Standard",
        },
        {
            title: "Premium",
            price: <><del>$50/month</del> Free</>,
            features: [
                "Everything in Standard",
                "One Click Auto-Apply",
                "Premium Insider Connections",
                "Advanced Candidacy Evaluation",
                "50 Premium Opportunities/Week"
            ],
            buttonText: "Choose Premium",
            active: true
        },
    ];

    console.log(isMobile);

    // Sort pricingData to have the active tier first if isMobile is true
    const sortedPricingData = isMobile
        ? [...pricingData].reverse()
        : pricingData;

    // Find the maximum number of features
    const maxFeatures = Math.max(...sortedPricingData.map(tier => tier.features.length));

    // Pad the features array with empty strings to match the length of the longest features array
    const paddedPricingData = sortedPricingData.map(tier => ({
        ...tier,
        features: [...tier.features, ...Array(maxFeatures - tier.features.length).fill("-")]
    }));

    // Calculate the column span based on the number of pricing tiers
    const colSpan = Math.floor(24 / pricingData.length);

    return (
        <div style={{ padding: "20px" }}>
            <Row gutter={[16, 16]} justify="center">
                {paddedPricingData.map((tier, index) => (
                    <Col xs={24} md={colSpan} key={index} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Card
                            title={tier.title}
                            bordered={false}
                            style={{
                                textAlign: "center",
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                border: tier.active ? '2px solid #1890ff' : '1px solid #d9d9d9', // Apply different border if active
                                backgroundColor: tier.active ? 'white' : '#f5f5f5', // Apply different background color if not active
                                color: tier.active ? 'inherit' : '#bfbfbf', // Apply different text color if not active
                                opacity: tier.active ? 1 : 0.6, // Apply opacity if not active
                                pointerEvents: tier.active ? 'auto' : 'none' // Disable pointer events if not active
                            }}
                            actions={[
                                <Button type="primary" className="button-color-premium" style={{ textAlign: 'center', marginRight: '0px' }}>{tier.active ? "Selected" : tier.buttonText}</Button>
                            ]}
                        >
                            <div style={{ flex: 'none' }}>
                                <p style={{ fontSize: "24px", fontWeight: "bold", margin: 0 }}>{tier.price}</p>
                            </div>
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                <ul style={{ listStyleType: "none", padding: 0, margin: 0, flex: 'none' }}>
                                    {tier.features.map((feature, idx) => (
                                        <li style={{ marginBottom: "10px" }} key={idx}>{feature}</li>
                                    ))}
                                </ul>
                            </div>
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    );;
};

export default PricingTable;