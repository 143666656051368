import React, { useState, useEffect, useContext } from 'react';
import { Button, message, Steps, theme, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../contexts/userContext';
import { useQuery } from '@apollo/client';
import { QUERY_ALL_JOBS } from '../../../utils/queries';
import { useMutation } from '@apollo/client';
import { SET_WELCOME_WIZARD } from '../../../utils/mutations';
import { fetchGenerateMatches } from '../../../utils/FindMatchAPIs/generateMatches.js';
import { ADD_JOB } from '../../../utils/mutations';
import { ADD_KEYWORDS_TO_USER } from '../../../utils/mutations';
import { ADD_MATCHER_EMAIL_TO_USER } from '../../../utils/mutations';
import { fetchCraftMatcherEmail } from '../../../utils/CrafteMessageAPIs/craftMatcherEmail.js';
import { backgroundJobCheck } from '../../../utils/helpers/background';
import { startGenerateMatchesTask, getGenerateMatchesTaskStatus, getGenerateMatchesTaskResult } from '../../../utils/FindMatchAPIs/generateMatches.js';

//import { steps } from './BetaOneSteps/index.js';
import { steps as getSteps } from './NewSteps/index.js';

import useIsMobile from '../../../contexts/useIsMobile';




const WelcomeModal = ({ visible, onClose }) => {
    const [nextDisabled, setNextDisabled] = useState(false);
    const [countdown, setCountdown] = useState(5);
    const [setWelcomeWizard] = useMutation(SET_WELCOME_WIZARD);
    const isMobile = useIsMobile(); // Use the custom hook

    const [current, setCurrent] = useState(() => {
        // Get the current step from the local storage
        // const savedStep = localStorage.getItem('currentStep'); -- NOT GETTING STEPS FROM LOCAL STORAGE
        // If there is a current step stored in the local storage, return it, otherwise return 0
        //return savedStep ? Number(savedStep) : 0;
        return 0;
    });

    const { user, refetchUser, myResumeData, myResumeDataLoading } = useContext(UserContext);
    const { data: jobData, refetch: refetchJobs } = useQuery(QUERY_ALL_JOBS);
    const [allJobs, setAllJobs] = useState([]);
    const [welcomeWizardData, setWelcomeWizardData] = useState(null);
    const [completeDisable, setCompleteDisable] = useState(false);
    const [addJob] = useMutation(ADD_JOB);
    const [addKeywordsToUser] = useMutation(ADD_KEYWORDS_TO_USER);
    const [addMatcherEmailToUser] = useMutation(ADD_MATCHER_EMAIL_TO_USER);
    const [loadingMatches, setLoadingMatches] = useState(false);

    useEffect(() => {
        if (jobData) {
            setAllJobs(jobData);
        }
    }, [jobData]);


    useEffect(() => {
        if (user) {
            const data = {
                targetJob: user.targetJob ? user.targetJob : undefined,
                yearsExperience: user.yearsExperience ? user.yearsExperience : null,
                targetJobLevel: user.targetJobLevel ? user.targetJobLevel : "",
                targetCompanies: user.targetCompanies.filter(company => company != null).map(company => company._id),
                targetLocation: user.targetLocation ? (({ __typename, ...rest }) => rest)(user.targetLocation) : null,
            };

            setWelcomeWizardData(data);
        }
    }, [user]);


    //!!!NOT SETTING STEPS RIGHT NOW!!!
    // useEffect(() => {
    //     // Store the current step in the local storage whenever it changes
    //     localStorage.setItem('currentStep', current);
    // }, [current]);

    //If user's target job has changed, update the user's keywords
    useEffect(() => {
        //--
    }, [welcomeWizardData]);

    const next = async () => {

        //Add Custom Job to DB if user entered one
        if (welcomeWizardData.customTargetJob && welcomeWizardData.targetJobField) {

            //Add New Job to DB
            try {
                var newCustomJob = await addJob({
                    variables: {
                        title: welcomeWizardData.customTargetJob,
                        jobFields: welcomeWizardData.targetJobField._id,
                        custom: true,
                    },
                });

                var keywordsFromJobField = welcomeWizardData.targetJobField.Keywords ? welcomeWizardData.targetJobField.Keywords : [];


                //Update Welcome Wizard Data
                setWelcomeWizardData(prevState => {
                    const { customTargetJob, targetJobField, ...rest } = prevState;
                    return {
                        ...rest,
                        targetJob: newCustomJob.data.addJob,
                    };
                });

                console.log('Refetching Jobs...');
                refetchJobs();


            } catch (err) {
                console.error(err);
            }
        }



        //Update User DB
        try {
            await setWelcomeWizard({
                variables: {
                    targetJob: newCustomJob ? newCustomJob.data.addJob._id : welcomeWizardData.targetJob._id,
                    yearsExperience: welcomeWizardData.yearsExperience,
                    targetJobLevel: welcomeWizardData.targetJobLevel,
                    targetCompanies: welcomeWizardData.targetCompanies,
                    targetLocation: welcomeWizardData.targetLocation,
                },
            });
        } catch (err) {
            console.error(err);
        }

        //Trigger Match Function on last step
        if (current + 1 == steps.length - 1) {
            setLoadingMatches(true);
            setCurrent(current + 1)
            console.log('Finding Matches...');

            // await new Promise(r => setTimeout(r, 6000));

            //Find Matches and wait min of 6 seconds
            // Start generateMatches task and get a task ID
            let taskResponse = await startGenerateMatchesTask(user._id);
            let taskId = taskResponse.data.taskID;

            // Check the status of the task and get the result
            let matchResultsPromise = backgroundJobCheck(getGenerateMatchesTaskStatus, getGenerateMatchesTaskResult, taskId);

            // Create a timeout promise that resolves after 6 seconds
            const timeoutPromise = new Promise(resolve => setTimeout(resolve, 6000));

            // Wait for both the match results and the timeout
            let [matchResults] = await Promise.all([matchResultsPromise, timeoutPromise]);


            //Refetch User
            refetchUser()
            setLoadingMatches(false);
        }

        //Move to next step or close modal
        if (current + 1 < steps.length) {
            setCurrent(current + 1);
        } else {
            onClose();
        }

        // Add Keywords to User & Generate Matcher Email - We are past the target job step
        if (current + 1 === 3) {
            console.log('Adding Keywords to User');
            var keywordsToAdd = keywordsFromJobField ? keywordsFromJobField : welcomeWizardData.targetJob.jobFields.Keywords;
            addKeywordsToUser({
                variables: {
                    keywords: keywordsToAdd,
                },
            });
            console.log('Creating Email For Matcher');
            console.log("generating email")

            var formattedWorkExperience = '';
            var formattedEducation = '';
            var matcherEmailJson = {
                email_subject: 'Networking Opportunity',
                email_body: 'I hope this message finds you well. I am reaching out to explore potential networking opportunities with your esteemed company. I believe that connecting with professionals in your organization could be mutually beneficial.\n\nI would love to schedule a brief meeting or call at your convenience to discuss how we might collaborate or share insights. Thank you for considering my request. I look forward to the possibility of connecting with you.',
            };


            if (myResumeData) {
                formattedWorkExperience = myResumeData.work.map(work => {
                    const highlights = work.highlights.map(highlight => `- ${highlight}`).join('\n');
                    return `Position: ${work.position}\nCompany: ${work.name}\nHighlights:\n${highlights}\n`;
                }).join('\n');

                formattedEducation = myResumeData.education.map(education => {
                    const highlights = education.highlights.map(highlight => `- ${highlight}`).join('\n');
                    return `School: ${education.institution}\nDegree: ${education.studyType}\nHighlights: ${highlights}\n`;
                }).join('\n');

                var matcherEmail = await fetchCraftMatcherEmail(formattedEducation, formattedWorkExperience, newCustomJob ? newCustomJob.data.addJob.title : welcomeWizardData.targetJob.title);
                matcherEmailJson = JSON.parse(matcherEmail.matcherEmail)
            }


            // var matcherEmail = await fetchCraftMatcherEmail(formattedEducation, formattedWorkExperience, newCustomJob ? newCustomJob.data.addJob.title : welcomeWizardData.targetJob.title);
            // matcherEmailJson = JSON.parse(matcherEmail.matcherEmail)


            addMatcherEmailToUser({
                variables: {
                    matcherEmails: {
                        theme: 'First Email',
                        emailSubject: matcherEmailJson.email_subject,
                        emailBody: matcherEmailJson.email_body,
                    }
                },
            });
        }
    };

    useEffect(() => {
        let timer;
        if (nextDisabled && countdown > 0) {
            timer = setTimeout(() => {
                setCountdown(countdown - 1);
            }, 1000);
        } else if (countdown === 0) {
            setNextDisabled(false);
        }

        return () => clearTimeout(timer);
    }, [nextDisabled, countdown]);


    const prev = () => {
        setCurrent(current - 1);
    };

    const steps = getSteps(allJobs, welcomeWizardData, setWelcomeWizardData, next, onClose, setCompleteDisable, loadingMatches);

    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));

    const renderSteps = () => {
        const startIndex = current;
        const visibleItem = items[startIndex];
    
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                <div style={{ padding: '0 10px', textAlign: 'center', flex: '1 0 0' }}>
                    <div style={{
                        width: '30px',
                        height: '30px',
                        borderRadius: '50%',
                        backgroundColor: '#1890ff',
                        color: '#fff',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '0 auto'
                    }}>
                        {startIndex + 1}
                    </div>
                    <div style={{ marginTop: '8px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {visibleItem.title}
                    </div>
                </div>
            </div>
        );
    };

    const { token } = theme.useToken();
    const contentStyle = {
        // lineHeight: '360px',
        // color: token.colorTextTertiary,
        // backgroundColor: token.colorFillAlter,
        // borderRadius: token.borderRadiusLG,
        // border: `1px dashed ${token.colorBorder}`,
        // marginTop: 16,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: '100%',

    };

    const mobileBodyStyle = {
        height: '80vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    };

    const defaultBodyStyle = {
        height: '80vh',
        alignItems: 'center',
        justifyContent: 'center'
    };

    const mobileWidth = '90vw';

    const defaultWidth = '80vw';

    return (
        <Modal
            title=" "
            centered
            visible={visible}
            onCancel={current === items.length - 1 ? onClose : undefined}
            closable={false}
            footer={
                <div>
                    {current > 0 && current < steps.length - 1 &&  (
                        <Button
                            style={{
                                margin: '0 8px',
                            }}
                            onClick={() => prev()}
                        >
                            Previous
                        </Button>
                    )}
                    {current < steps.length - 1 && (
                        <Button
                            disabled={completeDisable}
                            loading={nextDisabled}
                            onClick={() => next()}
                            style={{ backgroundColor: completeDisable ? '' : '#fcdc5c' }}
                        >
                            {nextDisabled ? `Next (${countdown})` : 'Next'}
                        </Button>
                    )}
                </div>
            }
            width={isMobile ? mobileWidth : defaultWidth}
            bodyStyle={isMobile ? mobileBodyStyle : defaultBodyStyle}
            maskClosable={false}
        >
            <>
                {isMobile ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                        {renderSteps()}
                    </div>
                ) : (
                    <Steps current={current} items={items} />
                )}
                <div style={contentStyle}>{steps[current].content}</div>

                <div
                    style={{
                        marginTop: 14,
                    }}
                >
                </div>
            </>
        </Modal>
    );
}

export default WelcomeModal;