import React, { useState, useContext, useEffect, useRef } from "react";
import { Card, Button, Grid, Row, Col, Divider, Typography, Tag, Rate, Tooltip } from 'antd'
import JobPostingItem from '../JobPostingItem'
import { UserContext } from '../../../contexts/userContext';
import { useMutation } from "@apollo/client";
//Not Interested Job Posting
import { REMOVE_KEYWORDS_FROM_USER } from "../../../utils/mutations";
import { ADD_KEYWORDS_TO_USER } from "../../../utils/mutations";
import { NOT_INTERESTED_JOB_POSTING } from "../../../utils/mutations";
import useIsMobile from '../../../contexts/useIsMobile';
import '../CompanyMatchCard/MatchCard.css';
import JobPostingDetailsCard from '../JobPostingItem/JobPostingDetailsCard';
import { ADD_APPLICATION } from '../../../utils/mutations';
import KeywordsScroll from '../CardComponents/KeywordScroller';
import { ADD_MATCH_LIKED } from '../../../utils/mutations';



const { Title, Paragraph } = Typography;

const JobPostingsMatchCard = ({ match, next }) => {
    const isMobile = useIsMobile();
    const [isExpanded, setIsExpanded] = useState(false);
    const { user } = useContext(UserContext);
    //Not Interested Job Posting
    const [remove_keywords_from_user] = useMutation(REMOVE_KEYWORDS_FROM_USER);
    const [add_keywords_to_user] = useMutation(ADD_KEYWORDS_TO_USER);
    const [addApplication, { error }] = useMutation(ADD_APPLICATION);
    const [not_interested_job_posting] = useMutation(NOT_INTERESTED_JOB_POSTING);
    const [addMatchLiked, { error: matchError }] = useMutation(ADD_MATCH_LIKED);

    const handleNotInterested = async () => {

        try {
            
            not_interested_job_posting({
                variables: { 
                    id: match._id,
                    notInterestedTo: user._id
                 }
            });

            remove_keywords_from_user({
                variables: {
                    keywords: match.keywords.slice(0, 20)
                }
            });

            next(true);

        } catch (err) {
            console.error(err);
        }
    }

    const ensureProtocol = (url) => {
        if (!/^https?:\/\//i.test(url)) {
            return 'http://' + url;
        }
        return url;
    };

    const handleSave = async () => {

        try {
            addMatchLiked()
        } catch (err) {
            console.error(err);
        }

        //Save Job Posting To Applications
        try {
            await addApplication({
                variables: { job: match._id, status: "Saved" }
            });

        } catch (err) {
            console.error(err);
        }

        //Add Keywords to User
        try {
            await add_keywords_to_user({
                variables: {
                    keywords: match.keywords.slice(0, 20) //Limiting Payload To 20 Keywords
                }
            });
        } catch (err) {
            console.error(err);
        }

        next()
    }

    const noJobDescription = `Job Description for ${match.title} is not available at the moment. Please visit this link for more information: ${match.link}`;

    return (
        <div className="full-match-card">
            <button
                className="not-interested"
                onClick={handleNotInterested}
            >
                Not Interested
            </button>
            <Row gutter={[24, 8]} className="match-card-row" style={{ display: 'flex' }}>
                <Col xs={24} md={10} style={{ display: 'flex', flexDirection: 'column' }}>
                    <Row gutter={[0, 8]}>
                        <Col span={24}>
                            <JobPostingItem job={match} forMatcher={true} />
                        </Col>
                    </Row>
                    {match.keywords && match.keywords.length > 0 && (
                    <Row gutter={[0, 8]} style={{ marginTop: '5px' }}>
                        <Card style={{ marginTop: '5px', flex: '1', width: '100%' }}>
                            <KeywordsScroll keywords={match.keywords} />
                        </Card>
                    </Row>
                    )}
                    <Row gutter={[0, 8]} style={{ marginTop: '5px', flex: '1' }}>
                        <Col span={24}>
                            <JobPostingDetailsCard jobPosting={match} />
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} md={14} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    {/* Job Description */}
                    <Card title="Job Description" style={{ flex: '1', maxHeight: '70vh', overflowY: 'auto' }}>
                        <Paragraph style={{ whiteSpace: 'pre-wrap' }}>
                            {match.description ? match.description : noJobDescription}
                        </Paragraph>
                    </Card>
                    <div className="button-container">
                        <Button type="primary" className="button-color" onClick={handleSave}>Save</Button>
                        <Tooltip title="Auto Apply is not available for this job posting">
                            <div>
                                <Button disabled={true} type="primary" className="button-color-premium">Auto Apply</Button>
                            </div>
                        </Tooltip>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default JobPostingsMatchCard;