import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Spin } from 'antd';
import { useQuery } from '@apollo/client';
import { QUERY_ME } from '../../../utils/queries';

const AccountDetails = () => {
    const [form] = Form.useForm(); // Create a form instance

    const { data, loading } = useQuery(QUERY_ME);

    useEffect(() => {
        if (data) {
            // Use setFieldsValue to set initial values after data is loaded
            form.setFieldsValue({
                'First Name': data.user.firstName,
                'Last Name': data.user.lastName,
                'Email': data.user.email,
                // Set other fields here if needed
            });
        }
    }, [data, form]);

    const handleFormSubmit = (values) => {
        // Handle form submission here
        console.log('Form values:', values);
    };

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    if (loading) {
        // Display a loading indicator while waiting for data to load
        return <Spin size="large" />;
    }

    return (
        <div style={{margin: '10px'}}>
            <h2>Account Details</h2>
            <Form
                {...layout}
                form={form} // Pass the form instance to the Form component
                name="nest-messages"
                style={{ maxWidth: 600 }}
                onFinish={handleFormSubmit}
            >
                <Form.Item name='First Name' label="First Name" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item name='Last Name'label="Last Name" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item name='Email' label="Email" rules={[{ type: 'email', required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item name='Password' label="Password" rules={[{ required: true }]}>
                    <Input.Password />
                </Form.Item>
                {/* <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item> */}
            </Form>
        </div>
    );
};

export default AccountDetails;





