import React, { useState, useEffect } from 'react';
import { Input, Tooltip, Popconfirm, Spin, Card, Radio } from 'antd';
import { CopyTwoTone, ThunderboltFilled, CheckOutlined } from '@ant-design/icons';
import useTypingEffect from './typingEffect'
import useIsMobile from '../../../contexts/useIsMobile';
import './EmailComposer.css';
import { fetchUniversalEmail } from '../../../utils/CrafteMessageAPIs/universalEmailCrafter.js';

import { fetchCraftImprovedMatcherEmail } from '../../../utils/CrafteMessageAPIs/craftMatcherEmail';

const { TextArea } = Input;

var ROWS

const EmailCompose = ({ newSubject, setNewSubject, newBody, setNewBody, messageRows, forMatcher, match, replyToMessage }) => {

    const isMobile = useIsMobile();
    const [loadingMessage, setLoadingMessage] = useState(false);
    const [isInitialRender, setIsInitialRender] = useState(true);
    const typedSubjectText = useTypingEffect(newSubject, 25);
    const typedBodyText = useTypingEffect(newBody, 5);
    const [replyMainPrompt, setReplyMainPrompt] = useState('');

    const ROWS = messageRows || (isMobile ? 4 : 8);

    const editSubject = (value) => {
        setIsInitialRender(false);
        setNewSubject(value);
    }

    const editBody = (value) => {
        setIsInitialRender(false);
        setNewBody(value);
    }



    const copyMessageToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(newBody);
            // Optionally, you can set a state or display a notification to inform the user that the text has been copied
            console.log("Message copied to clipboard!");
        } catch (err) {
            console.error("Failed to copy text: ", err);
        }
    };

    const generateImprovedMatcherEmail = async () => {

        setLoadingMessage(true);

        try {
            const response = await fetchCraftImprovedMatcherEmail(newBody, `${match.companyName}: ${match.description}`, match.keywords, match.industry);

            const jsonResponse = JSON.parse(response.improvedMatcherEmail);

            editSubject(jsonResponse.email_subject);
            editBody(jsonResponse.email_body);

        } catch (error) {
            console.error("There was a problem with the fetch operation:", error.message);
        }

        setLoadingMessage(false);

    }

    const handleReplyToMainPrompt = async (value) => {

        if (value === 'schedule') {
            setReplyMainPrompt(`Here is the reply: ${replyToMessage}\n\nFor my reply: I would like to schedule a meeting. Please let me know your availability.`);
        } else if (value === 'advice') {
            setReplyMainPrompt(`Here is the reply: ${replyToMessage}\n\nFor my reply: I understand there is no direct job opportunity. I would greatly appreciate any advice or feedback you can provide.`);
        } else if (value === 'maintain') {
            setReplyMainPrompt(`Here is the reply: ${replyToMessage}\n\nFor my reply: I understand there is no direct job opportunity at this time, but would like to craft a reply that helps maintain the connection in the hope I could be considered for future opportunities.`);
        } else if (value === 'network') {
            setReplyMainPrompt(`Here is the reply: ${replyToMessage}\n\nFor my reply: I would greatly appreciate any introductions or referrals you could provide.`);
        }

        console.log(replyMainPrompt);

    }

    const fetchReplyEmail = async () => {
        setLoadingMessage(true);

        const systemPrompt = "You are an expert at writing emails to companies & company contacts I am interested in working for. I want you to craft the best reply to the email I have received. \n\n Do not include any placeholders (EX: [mention a specific project or value...], [Name], [Company Name]). \n"

        try {
            const response = await fetchUniversalEmail(systemPrompt, replyMainPrompt);

            const jsonResponse = JSON.parse(response.universalCraftedEmail);

            console.log(jsonResponse);

            editSubject(jsonResponse.email_subject);
            editBody(jsonResponse.email_body);

        } catch (error) {
            console.error("There was a problem with the fetch operation:", error.message);
        }

        setLoadingMessage(false);
    }


    


    //Set New Body to be the typed body text
    //setNewBody(typedBodyText)
    // useEffect(() => {
    //     setNewBody(typedBodyText);
    // }, []);


    return (
        <div className='mainEmailComposerContainer'>
            {loadingMessage ? (
                <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                    <Card style={{ marginBottom: '10px', marginTop: '10px', padding: '2.5%' }}>
                        <Spin
                            size="large"
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)'
                            }}
                        />
                    </Card>
                </div>
            ) : (
                <Input
                    placeholder="Subject"
                    value={forMatcher ? isInitialRender ? typedSubjectText : newSubject : newSubject}
                    onChange={e => editSubject(e.target.value)}
                    style={{ marginBottom: 10 }}
                />
            )}
            <div style={{ position: 'relative', width: '100%' }}> {/* Add this container */}
                {loadingMessage ? (
                    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                        <Card style={{ marginBottom: '10px', marginTop: '10px', padding: '30%' }}>
                            <Spin
                                size="large"
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)'
                                }}
                            />
                        </Card>
                    </div>
                ) : (
                    <>
                        <TextArea
                            placeholder="Message"
                            value={forMatcher ? isInitialRender ? typedBodyText : newBody : newBody}
                            onChange={e => editBody(e.target.value)}
                            style={{ flex: 1, marginBottom: 10 }}
                            rows={ROWS}
                        />
                        {forMatcher ? (
                            <Tooltip title="Rewrite!">
                                <ThunderboltFilled
                                    onClick={generateImprovedMatcherEmail}
                                    style={{
                                        color: 'gold',
                                        position: 'absolute',
                                        bottom: '20px',
                                        right: '10px',
                                        fontSize: '30px'
                                    }}
                                >
                                    Copy
                                </ThunderboltFilled>
                            </Tooltip>

                        ) : (

                            replyToMessage && (
                                <Popconfirm
                                    title="Reply Suggestions"
                                    placement="topRight"
                                    description={
                                        <>
                                            <div style={{ width: isMobile ? '80vw' : '500px' }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly' }}>
                                                    <Radio.Group onChange={(e) => handleReplyToMainPrompt(e.target.value)} style={{ width: '100%' }}>
                                                        <Radio.Button value="schedule" style={{ width: '100%', textAlign: 'center', marginBottom: '10px', marginTop: '5px', borderRadius: '0' }}>Schedule A Meeting</Radio.Button>
                                                        <Radio.Button value="advice" style={{ width: '100%', textAlign: 'center', marginBottom: '10px', borderRadius: '0' }}>Seek Advice Or Feedback</Radio.Button>
                                                        <Radio.Button value="maintain" style={{ width: '100%', textAlign: 'center', marginBottom: '10px', borderRadius: '0' }}>Maintain Network For Future</Radio.Button>
                                                        <Radio.Button value="network" style={{ width: '100%', textAlign: 'center', borderRadius: '0' }}>Network Within Their Network</Radio.Button>
                                                    </Radio.Group>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    onConfirm={fetchReplyEmail}
                                    okText="Generate Reply"
                                    okButtonProps={{ className: 'button-color-premium' }}
                                    cancelText="Cancel"
                                    icon={<CheckOutlined style={{ color: 'green' }} />}
                                >
                                    <Tooltip title="Reply Suggestions">
                                        <ThunderboltFilled
                                            onClick={copyMessageToClipboard}
                                            style={{
                                                color: 'gold',
                                                position: 'absolute',
                                                bottom: '50px',
                                                right: '10px',
                                                fontSize: '18px'
                                            }}
                                        >
                                            Copy
                                        </ThunderboltFilled>
                                    </Tooltip>
                                </Popconfirm>
                            )
                        )}

                        {!forMatcher && (
                            <Tooltip title="Copy Message">
                                <CopyTwoTone
                                    onClick={copyMessageToClipboard}
                                    style={{
                                        position: 'absolute',
                                        bottom: '20px',
                                        right: '10px',
                                        fontSize: '18px'
                                    }}
                                >
                                    Copy
                                </CopyTwoTone>
                            </Tooltip>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default EmailCompose;
