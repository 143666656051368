import axios from 'axios';


export const fetchUniversalEmail = async (system, mainPrompt) => {

    const data = {
        system: system,
        mainPrompt: mainPrompt
    };

    try {

        const response = await axios.post(`/message/universalCraftEmail`, data);

        return response.data;

    } catch (error) {

        console.error("There was a problem with the fetch operation:", error.message);

    }

}

