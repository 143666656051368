import React, { useState } from 'react';
import { Button, Modal, Typography, Space } from 'antd';
import DOMPurify from 'dompurify';

const { Text } = Typography;

const LastMessageModal = ({ date, firstMessage, followUpMessage }) => {
  const sanitizedMessage = DOMPurify.sanitize(firstMessage);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const title = `Last Message on ${date}`;

  return (
    <>
      <Button style={{ width: '100%' }} size='small' onClick={showModal}>
        {date}
      </Button>
      <Modal
        title={title}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button key="close" onClick={handleCancel}>
            Close
          </Button>,
        ]}
      >
        <Space direction="vertical">
          {firstMessage.includes('<a href="/contacts?') ? (
            <span className='refer-to-contact' dangerouslySetInnerHTML={{ __html: sanitizedMessage }} />
          ) : (
            <Text><strong>Initial Message: </strong> {firstMessage}</Text>
          )}
          {followUpMessage ? (
            <Text style={{ display: 'block' }}> <strong>Latest Follow-Up: </strong> {followUpMessage}</Text>
          ) : null}
        </Space>
      </Modal>
    </>
  );
};
export default LastMessageModal;