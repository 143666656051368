import React, { useState, useEffect, useContext } from 'react';
import { Button, Modal, Input, Typography, Spin, Dropdown, Menu, Space, Checkbox, Tooltip, notification, Row, Col, List } from 'antd';
import { useMutation } from '@apollo/client';
import { ADD_JOB_POSTING_W_DATE_TO_CONTACT } from '../../../utils/mutations';
import { UPDATE_LAST_CONTACTED_COMPANY } from '../../../utils/mutations';
import { DownOutlined, LinkedinOutlined, PhoneOutlined, MailOutlined, LinkOutlined, CopyTwoTone } from '@ant-design/icons';
import { defaultLinkNetworkMessage, fetchMainContactMessage, additionalInfoNetowrkPlaceholder } from './network.js';
import { UPDATE_LAST_FOLLOW_UP } from '../../../utils/mutations';
import { UPDATE_LAST_FOLLOW_UP_COMPANY } from '../../../utils/mutations';
import { defaultFollowUpMessage, fetchFollowUpMessage, additionalFollowUpPlaceholder } from './followUp.js';
import { fetchSendEmail } from '../../../utils/EmailAPIs/sendEmail.js';
import { UserContext } from '../../../contexts/userContext.js'
import EmailCompose from '../../MailBox/EmailComposer/index.js'
import EmailItem from '../../MailBox/EmailItem/index.js'
import MailBoxView from '../../MailBox/EmailView/index.js'
import useIsMobile from '../../../contexts/useIsMobile';

const { Title, Text } = Typography;

const ContactMessageModal = ({ record, onClose, contact, messageType, myEmails }) => {

    const relationship = record.relationship ? record.relationship : ""
    const lastContacted = record.lastContacted ? record.lastContacted : ""
    const recordID = record._id
    const isMobile = useIsMobile();

    var recordDetails = "";
    if (contact === "company") {
        recordDetails = record.companyDetails;
    } else if (contact === "person") {
        recordDetails = record.contactDetails;
    }
    const firstName = recordDetails.firstName ? recordDetails.firstName : "";
    const lastName = recordDetails.lastName ? recordDetails.lastName : "";
    const position = recordDetails.jobTitle ? recordDetails.jobTitle : "";
    const company = recordDetails.company ? recordDetails.company.companyName : recordDetails.companyName ? recordDetails.companyName : "";
    const companyURL = recordDetails.companyWebsite ? recordDetails.companyWebsite : "";
    const email = recordDetails.email ? recordDetails.email : recordDetails.companyEmail ? recordDetails.companyEmail : "";
    const phone = recordDetails.phone ? recordDetails.phone : recordDetails.companyPhone ? recordDetails.companyPhone : "";
    const linkedIn = recordDetails.linkedIn ? recordDetails.linkedIn : recordDetails.companyLinkedIn ? recordDetails.companyLinkedIn : "";

    const companyOrPerson = contact
    const [loading, setLoading] = useState(false);
    const [updateContact] = useMutation(ADD_JOB_POSTING_W_DATE_TO_CONTACT);
    const [updateCompany] = useMutation(UPDATE_LAST_CONTACTED_COMPANY);
    const [selectedOption, setSelectedOption] = useState('Learn About Company')
    const [defaultInput, setDefaultInput] = useState(recordDetails.company ? recordDetails.company.companyName : recordDetails.companyName ? recordDetails.companyName : "")
    const [additionalInfo, setAdditionalInfo] = useState('')
    const [message, setMessage] = useState(defaultLinkNetworkMessage(firstName, position, company))
    const [coldOutreach, setColdOutreach] = useState(false)
    const [linkedInCharacterLimit, setLinkedInCharacterLimit] = useState(false)
    const [updateFollowUp] = useMutation(UPDATE_LAST_FOLLOW_UP);
    const [updateCompanyFollowUp] = useMutation(UPDATE_LAST_FOLLOW_UP_COMPANY);
    const [additionalPlaceholder, setAdditionalPlaceholder] = useState(additionalInfoNetowrkPlaceholder)
    const { user } = useContext(UserContext);
    const [emails, setEmails] = useState(myEmails);
    const [newSubject, setNewSubject] = useState('Looking to connect')

    useEffect(() => {
        if (messageType === "followUp") {
            setMessage(defaultFollowUpMessage(firstName, position, company))
        }
        if (messageType === "followUp") {
            setAdditionalPlaceholder(additionalFollowUpPlaceholder)
        }
    }, [messageType, firstName, position, company])


    const fetchMessage = async () => {
        var Mes = ""
        let name

        if (companyOrPerson === "company") {
            name = company
        } else {
            name = firstName
        }

        if (messageType === "followUp") {
            //Not using additionalInfo
            let additionalFollowupInfo = `${record.lastContactMessage ? `Here was my last message: ${record.lastContactMessage}` : ""} -Please keep the follow-up brief and do not include any placeholders in the email (EX: [mention a specific project or value...], [Name], [Company Name])` 

            Mes = await fetchFollowUpMessage(companyOrPerson, name, coldOutreach, additionalFollowupInfo, linkedInCharacterLimit);
        } else {
            Mes = await fetchMainContactMessage(companyOrPerson, name, selectedOption, defaultInput, coldOutreach, additionalInfo, linkedInCharacterLimit);
        }

        const jsonResponse = JSON.parse(Mes);

        //Need to set Subject and Message
        setNewSubject(jsonResponse.email_subject)
        setMessage(jsonResponse.email_body);
    };

    const handleOk = async () => {
        setLoading(true);
        try {
            await fetchMessage();
        } catch (error) {
            console.error("Error fetching the follow-up message:", error);
        } finally {
            setLoading(false);
        }
    };

    const copyMessageToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(message);
            // Optionally, you can set a state or display a notification to inform the user that the text has been copied
            console.log("Message copied to clipboard!");
        } catch (err) {
            console.error("Failed to copy text: ", err);
        }
    };

    const markAsSent = async () => {
        try {
            if (companyOrPerson === "company") {
                if (messageType === "followUp") {
                    await updateCompanyFollowUp({
                        variables: {
                            id: recordID,
                            lastfollowUp: new Date().toISOString().slice(0, 10),
                            lastfollowUpMessage: message,
                        }
                    });
                } else {
                    await updateCompany({
                        variables: {
                            id: recordID,
                            lastContacted: new Date().toISOString().slice(0, 10),
                            lastContactMessage: message,
                        }
                    });
                }
            } else {
                if (messageType === "followUp") {
                    await updateFollowUp({
                        variables: {
                            id: recordID,
                            lastfollowUp: new Date().toISOString().slice(0, 10),
                            lastfollowUpMessage: message,
                        }
                    });
                }
                else {
                    await updateContact({
                        variables: {
                            id: recordID,
                            lastContacted: new Date().toISOString().slice(0, 10),
                            lastContactMessage: message,
                        }
                    });
                }
            }
            onClose();
        } catch (error) {
            console.error("Error updating the application:", error);
        }
    };

    const sendEmail = async () => {
        try {
            //Send email
            const response = await fetchSendEmail(email, user.customUserEmail, newSubject, message, recordID, user._id);

            // Check if the response is an error
            if (response instanceof Error || !response) {
                console.log("Error sending email:", response);
                throw response;
            }

            notification.success({
                message: 'Email Sent',
                description: 'Your email has been sent successfully.',
                placement: 'topRight',
                duration: 10,
            });

            markAsSent();
        } catch (error) {

            if (email === undefined || email === null || email === '') {
                notification.error({
                    message: 'Error Sending Email',
                    description: 'Contact is missing an email address.',
                    placement: 'topRight',
                    duration: 10,
                });
            } else {

                notification.error({
                    message: 'Error Sending Email',
                    description: 'Error sending email. Please try again later.',
                    placement: 'topRight',
                    duration: 10,
                });
            }
        }

    };

    const menu = (
        <Menu onClick={(e) => {
            setSelectedOption(e.key);
            if (e.key === 'Learn About Company') {
                setDefaultInput(company);
            } else if (e.key === 'Learn About Industry/Position') {
                setDefaultInput(position);
            } else {
                setDefaultInput(company);
            }
        }}>
            <Menu.Item key="Learn About Company">Learn About Company</Menu.Item>
            <Menu.Item key="Learn About Industry/Position">Learn About Industry/Position</Menu.Item>
            <Menu.Item key="Asking for a Referal at">Ask for a Referal</Menu.Item>
            <Menu.Item key="Looking For Oportunities at">Looking For Oportunities</Menu.Item>
            <Menu.Item key="Other">Other</Menu.Item>
        </Menu>
    );


    return (
        <Modal
            visible={true}
            centered
            title=""
            onOk={handleOk}
            onCancel={onClose}
            width={isMobile ? '95vw' : '70vw'}
            bodyStyle={{ height: isMobile ? '75vh' : '100%', alignItems: 'center', justifyContent: 'center', overflow: 'auto', margin: isMobile ? '1px' : '10px' }}
            footer={null}
        >
            <Row gutter={[24, 8]}>
                <Col xs={24} md={12}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px', marginBottom: '10px' }}>
                        <Title level={4}><MailOutlined /> Contact</Title>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '5px' }}>
                            <Text strong>{firstName} {lastName}</Text>
                            <Text>{position} {company}</Text>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '40px' }}>
                            <Text strong>{relationship}</Text>
                            <Text>Last Contacted: {lastContacted ? new Date(parseInt(lastContacted, 10)).toLocaleDateString() : "N/A"}</Text>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Space size="middle">
                            {companyURL && <a href={companyURL}><LinkOutlined /></a>}
                            <a href={linkedIn}><LinkedinOutlined /></a>
                            <a href={`mailto:${email}`}><MailOutlined /></a>
                            <a href={`tel:${phone}`}><PhoneOutlined /></a>
                        </Space>
                    </div>

                    {messageType !== 'followUp' && (
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px', marginTop: '10px' }}>
                            <Dropdown overlay={menu} trigger={['click']}>
                                <Button>
                                    {selectedOption} <DownOutlined />
                                </Button>
                            </Dropdown>
                            <Input value={defaultInput} onChange={(e) => setDefaultInput(e.target.value)} />
                        </div>
                    )}
                    <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', marginBottom: '10px', textAlign: 'center' }}>
                        <Checkbox onChange={(e) => setColdOutreach(e.target.checked)}>Cold Outreach</Checkbox>
                        <Checkbox onChange={(e) => setLinkedInCharacterLimit(e.target.checked)}>LinkedIn Character Limit</Checkbox>
                    </div>

                    <EmailCompose
                        newSubject={newSubject}
                        setNewSubject={setNewSubject}
                        newBody={message}
                        setNewBody={setMessage}
                        messageRows={8} />
                    <div key="buttons" style={{ textAlign: 'center' }}>
                        <Button className='button-color-premium' loading={loading} onClick={handleOk}>
                            Generate Message
                        </Button>
                        {/* <Button onClick={copyMessageToClipboard}>Copy Message</Button> */}
                        <Button key="submit" disabled={loading} onClick={markAsSent}>
                            Mark as Sent
                        </Button>
                        <Button className='button-color' style={{ marginTop: isMobile ? '5px' : 0 }} disabled={loading} onClick={sendEmail} icon={<MailOutlined />}>
                            Send Email
                        </Button>
                    </div>
                </Col>
                <Col xs={24} md={12} style={{ maxHeight: '60vh', overflowY: 'auto' }}>
                    < MailBoxView myEmails={emails} />
                </Col>
            </Row>
        </Modal>
    );

}

export default ContactMessageModal;


