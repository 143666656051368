import React from 'react';
import { Card, Row, Col, Typography, Tag } from 'antd';
import { format, parseISO, isValid } from 'date-fns';

const { Paragraph, Text } = Typography;

const JobPostingDetailsCard = ({ jobPosting }) => {

    const formatDate = (timestamp) => {

        timestamp = typeof timestamp === 'string' ? Number(timestamp) : timestamp;

        const date = new Date(timestamp);
        
        // Check if the date is valid
        if (isNaN(date.getTime())) {
            console.log("Invalid Date");
            return 'Invalid Date';
        }
        
        // Format the date
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);
        
        return formattedDate;
    };
    
    return (
        <Card title="Additional Details">
            <Row gutter={[2, 2]}>
                {jobPosting.postDate && (
                    <Col span={24}>
                        <Paragraph>
                        <Text strong>Post Date:</Text> {formatDate(jobPosting.postDate)}
                        </Paragraph>
                    </Col>
                )}
                {jobPosting.deadline && (
                    <Col span={24}>
                        <Paragraph>
                            <Text strong>Deadline:</Text> {formatDate(jobPosting.deadline)}
                        </Paragraph>
                    </Col>
                )}
                {jobPosting.remote == true && (
                    <Col span={24}>
                        <Paragraph>
                            <Text strong>Remote:</Text> {jobPosting.remote ? 'Yes' : 'No'}
                        </Paragraph>
                    </Col>
                )}
                {jobPosting.link && (
                    <Col span={24}>
                        <Paragraph>
                            <Text strong>Link:</Text> <a href={jobPosting.link} target="_blank" rel="noopener noreferrer">View Posting</a>
                        </Paragraph>
                    </Col>
                )}
                {jobPosting.experienceLevel && (
                    <Col span={24}>
                        <Paragraph>
                            <Text strong>Experience Level:</Text> {jobPosting.experienceLevel}
                        </Paragraph>
                    </Col>
                )}
                {jobPosting.educationLevel && (
                    <Col span={24}>
                        <Paragraph>
                            <Text strong>Education Level:</Text> {jobPosting.educationLevel}
                        </Paragraph>
                    </Col>
                )}
                {jobPosting.category && (
                    <Col span={24}>
                        <Paragraph>
                            <Text strong>Category:</Text> {jobPosting.category}
                        </Paragraph>
                    </Col>
                )}
                {jobPosting.salary && (
                    <Col span={24}>
                        <Paragraph>
                            <Text strong>Salary:</Text> {jobPosting.salary}
                        </Paragraph>
                    </Col>
                )}
                {jobPosting.partOrFullTime && (
                    <Col span={24}>
                        <Paragraph>
                            <Text strong>Part/Full Time:</Text> {jobPosting.partOrFullTime}
                        </Paragraph>
                    </Col>
                )}
            </Row>
        </Card>
    );
};

export default JobPostingDetailsCard;