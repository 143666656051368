import React, { useState, useEffect, useContext } from 'react';
import { Button, Modal, Alert, Typography, message } from 'antd';
import { UserContext } from '../../../contexts/userContext';
import { Link } from 'react-router-dom';
const { Title, Text } = Typography;



const RecommendationsModal = ({ visible, onClose, reportFound }) => {;

    const { user, myResumeData } = useContext(UserContext);

    const hasLinkedInProfile = myResumeData && myResumeData.basics && myResumeData.basics.profiles.some(profile => profile.url.includes('linkedin'));

    const defaultMatcherEmail_body = "I hope this message finds you well. I am reaching out to explore potential networking opportunities with your esteemed company. I believe that connecting with professionals in your organization could be mutually beneficial.\n\nI would love to schedule a brief meeting or call at your convenience to discuss how we might collaborate or share insights. Thank you for considering my request. I look forward to the possibility of connecting with you.";
    const normalizeWhitespace = (str) => str.replace(/\n+/g, ' ').replace(/\s+/g, ' ').trim();
    const userEmailBody = normalizeWhitespace(user.matcherEmails[0].emailBody);
    const defaultEmailBody = normalizeWhitespace(defaultMatcherEmail_body);
    const isEmailBodyDefault = userEmailBody == defaultEmailBody;


    const recommendations = [
        {
            message: "Evaluate & Review Your Candidacy Score",
            description: "Review the full report to enhance your candidacy. Higher-tier users gain increased visibility to employers.",
            type: "info",
            buttonText: "Go to Candidacy Score",
            buttonLink: "/",
            condition: !reportFound
        },
        // {
        //     message: "Fine-Tune Your Outreach Email",
        //     description: "Customize your email to boost your chances of getting a response.",
        //     type: "info",
        //     buttonText: "Go to Outreach Email",
        //     buttonLink: "/profile/matcheremails"
        // },
        {
            message: "Improve Your Email Signature",
            description: "Ensure your email signature is professional and complete.",
            type: "info",
            buttonText: "Go to Email Signature",
            buttonLink: "/profile/matcheremails",
            condition: user && (() => {
                const nonEmptyFields = Object.values(user.matcherSignature).filter(value => value !== null && value !== undefined && value !== '');
                return nonEmptyFields.length < 4;
            })()
        },
        {
            message: "Missing LinkedIn Profile",
            description: "Add your LinkedIn profile to increase your credibility and visibility to employers.",
            type: "info",
            buttonText: "Add LinkedIn Profile",
            buttonLink: `${user.myResumeData ? `/edit-resume/` + user.myResumeData._id : '/profile/resume'}`,
            condition: !hasLinkedInProfile
        },
        {
            message: "Create a Custom Matcher Email",
            description: "Create a custom email template to send to employers.",
            type: "info",
            buttonText: "Create Email",
            buttonLink: "/profile/matcheremails",
            condition: isEmailBodyDefault
        }
    ];

    const filteredRecommendations = recommendations.filter(rec => rec.condition);

    return (
        <Modal
            title="Warning"
            visible={visible}
            onCancel={onClose}
            footer={null}
            width={800}
        >
            <div>
                <div style={{ marginBottom: '20px', textAlign: 'center' }}>
                    {/* <Title level={3}>Warning</Title> */}
                    <Text type="primary" style={{ fontSize: '18px' }}>
                        We strongly encourage you to review the following recommendations. Completing these steps can <strong>significantly increase your response rate</strong> for opportunities.
                    </Text>
                </div>
                {filteredRecommendations.map((rec, index) => (
                    <Alert
                        key={index}
                        message={<Text style={{ fontSize: '16px' }} strong>{rec.message}</Text>}
                        description={
                            <div>
                                <p>{rec.description}</p>
                                <Button className='button-color' type="secondary">
                                    <Link to={rec.buttonLink} style={{ color: 'white' }}>
                                        {rec.buttonText}
                                    </Link>
                                </Button>
                            </div>
                        }
                        type={rec.type}
                        showIcon
                        style={{ marginBottom: '16px' }}
                    />
                ))}

                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                    <Button className='button-color' type="primary" onClick={onClose}>
                        Close
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export default RecommendationsModal;