import React from 'react';

const scoreColor = (result) => {
    const score = (result.userBoost / result.maxBoost) * 100;

    if (score >= 80) {
        return 'green';
    } else if (score >= 60) {
        return 'blue';
    } else if (score >= 40) {
        return 'yellow';
    } else {
        return 'red';
    }
};

export default scoreColor;