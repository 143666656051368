import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import * as React from 'react';

const Billing = () => {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const card = elements.getElement(CardElement);
        const result = await stripe.createPaymentMethod({
            type: 'card',
            card: card,
        });

        const { paymentMethod } = result;

        if (result.error) {
            console.error(result.error);
        } else {
            // Send result to your backend or further process it on the frontend
            const response = await fetch("/stripe/create-customer", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    email: "pamela@testmail.com", // replace this with the user's email ----------------------------------------
                    paymentMethod: paymentMethod.id
                })
            });

            const data = await response.json();
            if (data.error) {
                console.error("Error creating customer:", data.error);
            } else {
                const customerId = data.customerId; // From the previous API call's response
                const subscriptionResponse = await fetch("/stripe/create-subscription", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        customerId: customerId,
                        planId: "price_1O2LRNEIXZ5v9gRWJbm3koEs" // replace with your Stripe plan ID ----------------------------------------
                    })
                });

                const subscriptionData = await subscriptionResponse.json();
                if (subscriptionData.error) {
                    console.error("Error creating subscription:", subscriptionData.error);
                } else {
                    // Handle successful subscription (e.g., redirect user to a success page or update their account status)
                    console.log("Success!")
                }
            }
        }
    };

    return (
        <div>
            <h3>Currently Unavailable</h3>
        </div>
    );

    return (
        <div>
            <h1>Subscription</h1>
            <div>
                <stripe-pricing-table
                    pricing-table-id="prctbl_1O2MaHEIXZ5v9gRWusdHHncB"
                    publishable-key="pk_test_51O2KXfEIXZ5v9gRWAs6kRVY2fZFLcjFpVCR4J2QeuoP1ZCrtgDgbdkYiadpBMdz0oeEzyYowALLluWpW7qgpdAcT00JnvBX01H"
                    customer-email="pamela@testmail.com" // replace this with the user's email ----------------------------------------
                />
            </div>
            {/* <form onSubmit={handleSubmit}>
                <CardElement />
                <button type="submit" disabled={!stripe}>
                    Pay
                </button>
            </form> */}
        </div>
    );
};

export default Billing;


//https://billing.stripe.com/p/login/test_6oEcQ18Mx2nJ9lS9AA?prefilled_email=pamela@testmail.com