import React from "react";
import { Modal, Button } from "antd";
import { Link } from 'react-router-dom';


const NoResumeDataModal = ({ onClose, warning, nextStep }) => {

    return (
        <Modal
            visible={true}
            title={<div style={{ textAlign: 'center' }}>Missing Resume</div>}
            footer={null}
            closable={false}
            centered
        >
            <div style={{ textAlign: 'center' }}>
                {warning ? (
                    <>
                        <p>Are you sure? Don't worry you can always upload later.</p>
                        <p>Unlock Powerful Features:</p>
                        <ul style={{ textAlign: 'center', paddingLeft: '0px' }}>
                            <li>- Unlock your Candidacy Score - See how you rank for your target job</li>
                            <li>- Personalized job recommendations, emails, and documents</li>
                            <li>- Enhanced profile visibility to employers</li>
                        </ul>
                        <Button className="button-color" type="primary" onClick={onClose}>
                            Back
                        </Button>
                        <Button className="button-color" type="primary" onClick={nextStep}>
                            Upload Later
                        </Button>
                    </>
                ) : (
                    <>
                        <p>There is no resume data to display. Please upload your resume first.</p>
                        <Button className="button-color" type="primary">
                            <Link to="/profile/resume">Upload Resume</Link>
                        </Button>
                    </>
                )}
            </div>
        </Modal>
    );
};

export default NoResumeDataModal;