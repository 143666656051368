import React, { useContext } from 'react';
import { Button, notification } from 'antd';
import { fetchSendEmail } from '../../../utils/EmailAPIs/sendEmail.js';
import { UserContext } from '../../../contexts/userContext.js';
import { useMutation } from '@apollo/client';
import { SAVE_COMPANY } from '../../../utils/mutations.js';
import { ADD_MATCH_LIKED } from '../../../utils/mutations.js';
import { UPDATE_LAST_CONTACTED_COMPANY } from '../../../utils/mutations.js';
import { ADD_JOB_POSTING_W_DATE_TO_CONTACT } from '../../../utils/mutations.js';
import { SAVE_CONTACT } from '../../../utils/mutations.js';
import { LINK_CONTACT_TO_COMPANY } from '../../../utils/mutations.js';
import useIsMobile from '../../../contexts/useIsMobile';


const EmailButtons = ({ handleChatBoxUpdate, toEmail, subject, body, userContactId, forMatcher, matcherContactID }) => {

    const { user } = useContext(UserContext);
    const [saveCompany, { error }] = useMutation(SAVE_COMPANY);
    const [addMatchLiked, { error: matchError }] = useMutation(ADD_MATCH_LIKED);
    const [updateCompany] = useMutation(UPDATE_LAST_CONTACTED_COMPANY);
    const [updateContact] = useMutation(ADD_JOB_POSTING_W_DATE_TO_CONTACT);
    const [saveContact] = useMutation(SAVE_CONTACT);
    const [linkContactToCompany] = useMutation(LINK_CONTACT_TO_COMPANY);
    const isMobile = useIsMobile();

    var displaySave = false
    if (forMatcher) {
        displaySave = true
    }

    const markAsSent = async (contactID, indivContactID, firstName) => {

        //Does not handle follow up email, will treat them as last contacted - see contactmessagemodel for different types of messages
        try {
            try {
                await updateCompany({
                    variables: {
                        id: contactID,
                        lastContacted: new Date().toISOString().slice(0, 10),
                        lastContactMessage: indivContactID
                            ? `<a href="/contacts?id=${indivContactID}&&filter=false">Refer To Contact</a>`
                            : body,
                    }
                });
                if (indivContactID) {

                    await linkContactToCompany({
                        variables: {
                            id: contactID,
                            linkedIndvContact: indivContactID
                        }
                    });

                    await updateContact({
                        variables: {
                            id: indivContactID,
                            lastContacted: new Date().toISOString().slice(0, 10),
                            lastContactMessage: body,
                        }
                    });
                }
            } catch (error) {
                await updateContact({
                    variables: {
                        id: contactID,
                        lastContacted: new Date().toISOString().slice(0, 10),
                        lastContactMessage: body,
                    }
                });
            }
        } catch (error) {
            console.error("Error updating the application:", error);
        }
    };

    const sendEmail = async () => {
        try {

            var contactID
            var indivContactID
            var indivContactFirstName

            if (forMatcher) {
                var newSaveCompany = await saveCompany({
                    variables: { company: userContactId }
                });
                contactID = newSaveCompany.data.saveCompany._id

                if (matcherContactID) {
                    var newSaveContact = await saveContact({
                        variables: { contact: matcherContactID }
                    });
                    indivContactID = newSaveContact.data.saveContact._id
                    //indivContactFirstName = newSaveContact.data.saveContact.firstName
                }


                try {
                    await addMatchLiked();
                } catch (error) {
                    console.error("Error adding match liked:", error);
                    // Handle the error as needed, e.g., log it, notify the user, etc.
                }
            } else {
                contactID = userContactId
            }

            //Send email
            const response = await fetchSendEmail(toEmail, user.customUserEmail, subject, body, indivContactID ? indivContactID : contactID, user._id,);

            // Check if the response is an error
            if (response instanceof Error || !response) {
                console.log("Error sending email:", response);
                throw response;
            }

            notification.success({
                message: 'Email Sent',
                description: isMobile ? "" : 'Your email has been sent successfully.',
                placement: 'topRight',
                duration: 2,
                showProgress: true,
                style: isMobile ? { padding: '10px', paddingRight: '20px', paddingBottom: '5px', width: '100%' } : "",
                closeIcon: isMobile ? false : true,

            });

            markAsSent(contactID, indivContactID, indivContactFirstName);
        } catch (error) {


            if (toEmail === undefined || toEmail === null || toEmail === '') {
                notification.error({
                    message: 'Error Sending Email',
                    description: 'Contact is missing an email address.',
                    placement: 'topRight',
                    duration: 10,
                });
            } else if (toEmail === "Hiremize") {
                notification.success({
                    message: 'Sent Sample Email',
                    description: '',
                    placement: 'topRight',
                    duration: 10,
                });
            } else {
                notification.error({
                    message: 'Error Sending Email',
                    description: 'Error sending email. Please try again later.',
                    placement: 'topRight',
                    duration: 10,
                });
            }
        }

    };
    //Also update mark as sent code to handle this type of email
    const save = async () => {
        try {
            var newSaveCompany = await saveCompany({
                variables: { company: userContactId }
            });

            //Save any potential contact as well
            if (matcherContactID) {
                var newSaveContact = await saveContact({
                    variables: { contact: matcherContactID }
                });
            }


            try {
                await addMatchLiked();
            } catch (error) {
                console.error("Error adding match liked:", error);
                // Handle the error as needed, e.g., log it, notify the user, etc.
            }

            notification.success({
                message: 'Company Saved',
                description: isMobile ? "" : 'This company has been saved to your contacts.',
                placement: 'topRight',
                duration: 2,
                showProgress: true,
                style: isMobile ? { padding: '10px', paddingRight: '20px', paddingBottom: '5px', width: '100%' } : "",
                closeIcon: isMobile ? false : true,

            });

        } catch (error) {
            notification.error({
                message: 'Error Saving Company',
                description: 'This feature is not available for the beta',
                placement: 'topRight',
                duration: 10,
            });
        }
    }


    // <Button className='button-color-premium'>Generate Message</Button>

    return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>

            {displaySave &&
                <Button
                    onClick={() => {
                        save()
                        handleChatBoxUpdate();
                    }}
                    style={{ width: '150px' }}>
                    Save for Later
                </Button>
            }


            <Button
                className='button-color-premium'
                style={{ width: '150px' }}
                onClick={() => {
                    handleChatBoxUpdate();
                    sendEmail();
                }}
            >
                {displaySave ? 'Save & Send' : 'Send'}
            </Button>
        </div>
    );
}

export default EmailButtons;
