import React from 'react';

const scoreResult = (result) => {
    const score = (result.userBoost / result.maxBoost) * 100;

    if (score >= 80) {
        return <span style={{ color: 'green' }}>Doing great! {`(${Math.round((result.userBoost / result.maxBoost) * 100)}%)`}</span>;
    } else if (score >= 60) {
        return <span style={{ color: 'blue' }}>Doing good!  {`(${Math.round((result.userBoost / result.maxBoost) * 100)}%)`}</span>;
    } else if (score >= 40) {
        return <span style={{ color: 'orange' }}>Doing Ok!  {`(${Math.round((result.userBoost / result.maxBoost) * 100)}%)`}</span>;
    } else {
        return <span style={{ color: 'red' , fontSize: '12px' }}>Needs Improvement!  {`(${Math.round((result.userBoost / result.maxBoost) * 100)}%)`}</span>;
    }
};

export default scoreResult;