import React, { useState, useEffect, useRef } from "react";
import { Layout, Row, Col, Card, Input, Button, Spin, Typography, Space, Dropdown, Select, Table, notification } from 'antd';
import { StarFilled, StarOutlined, CheckCircleFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useMutation } from '@apollo/client';
import './Resumes.css';
import { useTour } from "../../contexts/tourContext";
//Components
import ResumePreviewer from '../../components/Previewers/ResumePreviewer';
import BaseResumeAnalysis from '../../components/Modals/BaseResumeAnalysis'
import NoResumeDataModal from '../../components/Modals/NoResumeDataModal';
//Queries
import { QUERY_ME } from '../../utils/queries';
import { QUERY_MY_RESUMES_BY_USER_ID } from '../../utils/queries';
//Mutations
import { UPDATE_RESUME_DATA } from '../../utils/mutations';

const { Sider, Content } = Layout;
const { TextArea } = Input;
const { Text } = Typography;
const { Option } = Select;
const { Column } = Table;



const Resumes = () => {
    const [loading, setLoading] = useState(false);
    const { data: baseResumeData } = useQuery(QUERY_ME);
    const { data: dataResume, refetch } = useQuery(QUERY_MY_RESUMES_BY_USER_ID)
    const [noResumeDataModalVisible, setNoResumeDataModalVisible] = useState(false);
    const [BaseResumeAnalysisVisible, setBaseResumeAnalysisVisible] = useState(false);
    const [myResumes, setMyResumes] = useState([]);
    const [baseResume, setBaseResume] = useState(null);
    const [myBaseResumeData, setMyBaseResumeData] = useState(null);
    const [selectedResume, setSelectedResume] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [updateResumeData] = useMutation(UPDATE_RESUME_DATA);
    const [inputValue, setInputValue] = useState('');


    const { setTourSteps, startTour } = useTour();
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);


    console.log(baseResumeData);

    useEffect(() => {
        if (baseResumeData) {
            if (baseResumeData.user && baseResumeData.user.myResumeData) {
                setBaseResume(baseResumeData.user.myResumeData._id);
                setSelectedResume(baseResumeData.user.myResumeData._id);
                setMyBaseResumeData(baseResumeData.user.myResumeData.mainData);

                setTourSteps([
                    {
                        target: () => ref1.current,
                        placement: 'right',
                        title: 'Base Resume',
                        description: 'Click here to view or edit your base resume. Your base resume is the starting point for all your job applications.',
                    },
                    // {
                    //     target: () => ref2.current,
                    //     placement: 'right',
                    //     title: 'Resumes List',
                    //     description: 'View all your resumes here. You can edit, rename, and mark your preferred resumes as favorites.',
                    // },
                    //THIS was causing an illegal invocation error
                    {
                        target: () => ref3.current,
                        placement: 'left',
                        title: 'Resume Preview',
                        description: 'Preview your resume here. Click download to get a PDF copy.',
                    },
                ]);


                var isFirstTimeResumesPage = localStorage.getItem('isFirstTimeResumesPage');

                if (!isFirstTimeResumesPage) {
                    startTour();
                    localStorage.setItem('isFirstTimeResumesPage', 'true');
                }
            } else {
                setNoResumeDataModalVisible(true);
            }
        }
    }, [baseResumeData]);

    useEffect(() => {
        if (dataResume) {
            setMyResumes(dataResume.getAllResumeDataByUserID);
        }
    }, [dataResume]);

    console.log(myBaseResumeData);

    const handleSelectResume = (resumeId) => {
        setSelectedResume(resumeId);
    }

    const handleBaseResumeAnalysis = () => {
        setBaseResumeAnalysisVisible(true);
    }

    const favApp = async (id, status) => {
        console.log("Favourite");
        await updateResumeData({
            variables: {
                id: id,
                saved: status
            }
        })
        refetch();
    }

    const handleSaveName = async (id, name) => {
        console.log("Save Name");
        if (name === '') {
            name = 'Untitled Resume';
        }
        await updateResumeData({
            variables: {
                id: id,
                savedName: name
            }
        })
        refetch();
        notification.success({
            message: 'Success',
            description: 'Resume name saved successfully!',
            duration: 1.5
        });
    }



    return (
        <div style={{ marginLeft: '60px', height: '100%' }}>
            <Layout style={{ background: '#fff', height: '100%', margin: '10px' }}>
                <Sider width={475} style={{ background: '#fff', marginRight: '10px' }}>
                    <Card ref={ref1} title="Base Resume" style={{ marginBottom: '10px' }} onClick={() => {

                        handleSelectResume(baseResume)
                        setSelectedRow(null)
                    }}>

                        <Row>
                            <Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button type="primary" className="button-color" style={{ width: '100%' }}>
                                    <Link to={`/edit-resume/${baseResume}`}>Edit Resume</Link>
                                </Button>
                            </Col>
                            <Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                {/* <Button type="primary" className="button-color-premium" block onClick={() => handleBaseResumeAnalysis}>
                                    Base Resume Analysis
                                </Button> */}
                                <Button style={{ width: '100%' }} className='button-color' > <Link to={`/profile/resume`}>Resume Upload</Link></Button>
                            </Col>
                        </Row>
                        <Typography.Text type="secondary" style={{ color: 'black', textAlign: 'center', display: 'block', marginTop: '7.5px' }}>
                            Explore your improved resumes! Mark your preferred resumes as favorites and rename them for easy identification. Click to preview.
                        </Typography.Text>
                    </Card>
                    <Table
                        ref={ref2}
                        dataSource={myResumes}
                        loading={loading}
                        rowKey={record => record._id}
                        onRow={(record) => ({
                            onClick: () => {
                                handleSelectResume(record._id);
                                setSelectedRow(record._id);
                            },
                        })}
                        rowClassName={(record) => (record._id === selectedRow ? 'selected-row' : 'row')}


                    >
                        <Column title="Fav" dataIndex="saved" key="saved" width={10}
                            filters={[{ text: 'Favourites', value: true }]}
                            onFilter={(value, record) => record.saved === value}
                            render={(text, record) => (
                                <div className="favorite-icon" onClick={() => favApp(record._id, !record.saved)}>
                                    {record.saved ? <StarFilled style={{ color: "#fcdc5c" }} /> : <StarOutlined style={{ color: "#5ce4f4" }} />}
                                </div>
                            )}

                        />
                        <Column title="Resume Name" dataIndex="savedName" key="savedName" style={{ width: '100%', padding: '0' }}
                            onCell={() => ({
                                style: {
                                    padding: '1px', // adjust this value as needed
                                },
                            })}
                            render={(text, record) => (
                                // <Input placeholder={record.savedName} variant="borderless" />
                                <Space.Compact
                                    style={{
                                        width: '100%',
                                    }}
                                >
                                    <Input defaultValue={record.savedName} onChange={e => setInputValue(e.target.value)} />
                                    <Button
                                        className="button-color"
                                        style={{
                                            width: '1px',
                                            margin: '0',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                        type="primary"
                                        onClick={() => handleSaveName(record._id, inputValue)}
                                    >
                                        <CheckCircleFilled />
                                    </Button>
                                </Space.Compact>

                            )}

                        />
                        <Column
                            title="Edit"
                            key="action"
                            width={40}
                            render={(text, record) => (
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Button style={{ width: '100%', marginBottom: '2.5px' }} type="primary" className="button-color" size='small'>
                                        <Link to={`/edit-resume/${record._id}`}>Edit Resume</Link>
                                    </Button>
                                    {/* <Button style={{ width: '100%' }} type="primary" className="button-color-premium" size='small' onClick={handleBaseResumeAnalysis}>
                                        Resume Analysis
                                    </Button> */}
                                </div>

                            )}
                        />
                    </Table>
                </Sider>
                <Content ref={ref3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <ResumePreviewer ResumeID={selectedResume} />
                </Content>
                {noResumeDataModalVisible && <NoResumeDataModal onClose={() => setNoResumeDataModalVisible(false)} visible={noResumeDataModalVisible} />}
                {/* <BaseResumeAnalysis resumeID={baseResume} resume={myBaseResumeData} visible={BaseResumeAnalysisVisible} onClose={() => setBaseResumeAnalysisVisible(false)} /> */}
            </Layout>
        </div>
    )

}

export default Resumes;